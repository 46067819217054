const AppImages: any = {
    AppLogo: require("../Images/AppLogo.svg").default,
    HomeIcon: require("../Images/HomeIcon.svg").default,
    HomeBlackIcon: require("../Images/HomeBlackIcon.svg").default,
    ProductIcon: require("../Images/ProductIcon.svg").default,
    productpurple: require("../Images/productpurple.svg").default,
    vectorcontactimage: require("../Images/vectorcontactimage.svg").default,
    instaicon: require("../Images/instaicon.svg").default,
    linkedin: require("../Images/linkedin.svg").default,
    twitterIcon: require("../Images/twitterIcon.svg").default,
    pinterestIcon: require("../Images/pinterestIcon.svg").default,
    facebookicon: require("../Images/facebookicon.svg").default,
    youtubeIcone: require("../Images/youtubeIcone.svg").default,
    phoneIcon: require("../Images/phoneIcon.svg").default,
    mailicon: require("../Images/mailicon.svg").default,
    websiteicon: require("../Images/websiteicon.svg").default,
    location: require("../Images/locationnew.svg").default,
    Carouselone: require("../Images/Carouselone.png"),
    Carouseltwo: require("../Images/Carouseltwo.png"),
    Carouselthree: require("../Images/Carouselthree.png"),
    Carouselfour: require("../Images/Carouselfour.png"),
    Carouselfive: require("../Images/Carouselfive.png"),
    Carouselsix: require("../Images/Carouselsix.png"),
    Contact_pic: require("../Images/Contact_pic.png"),
    Sareesproduct: require("../Images/Sareesproduct.png"),
    chudidhaar_readymade_product: require("../Images/chudidhaar_readymade_product.png"),
    chudidhaar_materialsproduct: require("../Images/chudidhaar_materialsproduct.png"),
    nighties_product: require("../Images/nighties_product.png"),
    palazo_pant_product: require("../Images/palazo_pant_product.png"),
    leggings_product: require("../Images/leggings_product.png"),
    others: require("../Images/others.png"),
    sareessilk: require("../Images/sareessilk.png"),
    chuditharReadymades: require("../Images/chuditharReadymades.png"),
    chuditharmaterials: require("../Images/chuditharmaterials.png"),
    nightees: require("../Images/nightees.png"),
    jwelleryandassecories_product: require("../Images/jwelleryandassecories_product.png"),


    Bandles_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bandles_1.jpg",
    Bandles_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bandles_2.jpg",
    Bandles_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bandles_3.jpg",
    Bandles_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bandles_4.jpg",
    HairClips_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/HairClips_1.jpg",
    HairClips_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/HairClips_2.jpg",
    HairClips_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/HairClips_3.jpg",
    Earrings_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Earrings_1.jpg",
    Earrings_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Earrings_2.jpg",
    Earrings_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Earrings_3.jpg",
    Earrings_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Earrings_4.jpg",
    Earrings_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Earrings_5.jpg",
    Necklace_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_1.jpg",
    Necklace_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_2.jpg",
    Necklace_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_3.jpg",
    Necklace_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_4.jpg",
    Necklace_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_5.jpg",
    Necklace_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_6.jpg",
    Necklace_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_7.jpg",
    Necklace_8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_8.jpg",
    Necklace_9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_9.jpg",
    Necklace_10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_10.jpg",
    Necklace_11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_11.jpg",
    Necklace_12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_12.jpg",
    Necklace_13: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_13.jpg",
    Necklace_14: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_14.jpg",
    Necklace_15: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_15.jpg",
    Necklace_16: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_16.jpg",
    Necklace_17: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_17.jpg",
    Necklace_18: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_18.jpg",
    Necklace_19: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_19.jpg",
    Necklace_20: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_20.jpg",
    Necklace_21: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_21.jpg",
    Necklace_22: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_22.jpg",
    Necklace_23: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Necklace_23.jpg",
    Bindis_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bindis_1.jpg",
    Bindis_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bindis_2.jpg",
    Bindis_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bindis_3.jpg",
    Bindis_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bindis_4.jpg",
    Bindis_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bindis_5.jpg",
    Bindis_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bindis_6.jpg",
    Bindis_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bindis_7.jpg",
    Bindis_8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bindis_8.jpg",
    Bindis_9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bindis_9.jpg",
    Bindis_10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bindis_10.jpg",
    Bindis_11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bindis_11.jpg",
    Bindis_12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Bindis_12.jpg",
    NailPolish_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/NailPolish_1.jpg",
    NailPolish_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/NailPolish_2.jpg",
    NailPolish_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/NailPolish_3.jpg",
    NailPolish_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/NailPolish_4.jpg",
    KidsFrock_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsFrock_1.jpg",
    KidsFrock_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsFrock_2.jpg",
    KidsFrock_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsFrock_3.jpg",
    KidsFrock_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsFrock_4.jpg",
    KidsFrock_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsFrock_5.jpg",
    KidsFrock_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsFrock_6.jpg",
    KidsFrock_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsFrock_7.jpg",
    KidsTops_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsTops_1.jpg",
    KidsTops_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsTops_2.jpg",
    KidsTops_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsTops_3.jpg",
    KidsTops_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsTops_4.jpg",
    KidsWestern_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsWestern_1.jpg",
    KidsWestern_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsWestern_2.jpg",
    KidsWestern_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsWestern_3.jpg",
    KidsWestern_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsWestern_4.jpg",
    KidsWestern_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsWestern_5.jpg",
    KidsWestern_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsWestern_6.jpg",
    KidsWestern_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsWestern_7.jpg",
    KidsWestern_8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsWestern_8.jpg",
    KidsWestern_9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/KidsWestern_9.jpg",
    BanarasiSaree_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Banarasi_Sarees_1.jpg",
    BanarasiSaree_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Banarasi_Sarees_2.jpg",
    BanarasiSaree_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Banarasi_Sarees_3.jpg",
    BanarasiSaree_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Banarasi_Sarees_4.jpg",
    // BanarasiSaree_5:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/BanarasiSaree_5.jpg",
    BorderWorkSarees_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/BorderWorkSarees_1.jpg",
    BorderWorkSarees_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/BorderWorkSarees_2.jpg",
    BorderWorkSarees_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/BorderWorkSarees_3.jpg",
    EmbroiderySarees_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/EmbroiderySarees_1.jpg",
    EmbroiderySarees_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/EmbroiderySarees_2.jpg",
    EmbroiderySarees_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/EmbroiderySarees_3.jpg",
    EmbroiderySarees_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/EmbroiderySarees_4.jpg",
    EmbroiderySarees_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/EmbroiderySarees_5.jpg",
    EmbroiderySarees_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/EmbroiderySarees_6.jpg",
    EmbroiderySarees_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/EmbroiderySarees_7.jpg",
    EmbroiderySarees_8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/EmbroiderySarees_8.jpg",
    EmbroiderySarees_9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/EmbroiderySarees_9.jpg",
    EmbroiderySarees_10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/EmbroiderySarees_10.jpg",
    EmbroiderySarees_11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/EmbroiderySarees_11.jpg",
    EmbroiderySarees_12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/EmbroiderySarees_12.jpg",
    Poonam_Sarees_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Poonam-saree1.jpg",
    Poonam_Sarees_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Poonam-saree2.jpg",
    Poonam_Sarees_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Poonam-saree3.jpg",
    Poonam_Sarees_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Poonam-saree4.jpg",
    Poonam_Sarees_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Poonam-saree5.jpg",
    Poonam_Sarees_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Poonam-saree6.jpg",
    Poonam_Sarees_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Poonam-saree7.jpg",
    Poonam_Sarees_8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Poonam-saree8.jpg",
    Poonam_Sarees_9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Poonam-saree9.jpg",
    Poonam_Sarees_10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Poonam-saree10.jpg",
    Poonam_Sarees_11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Poonam-saree11.jpg",
    Poonam_Sarees_12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Poonam-saree12.jpg",




    CottonSarees_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree1.jpg",
    CottonSarees_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree2.jpg",
    CottonSarees_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree3.jpg",
    CottonSarees_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree4.jpg",
    CottonSarees_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree5.jpg",
    CottonSarees_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree6.jpg",
    CottonSarees_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree7.jpg",
    CottonSarees_8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree8.jpg",
    CottonSarees_9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree9.jpg",
    CottonSarees_10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree10.jpg",
    CottonSarees_11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree11.jpg",
    CottonSarees_12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree12.jpg",
    CottonSarees_13: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree13.jpg",
    CottonSarees_14: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree14.jpg",
    CottonSarees_15: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree15.jpg",
    CottonSarees_16: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree16.jpg",
    CottonSarees_17: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree17.jpg",
    CottonSarees_18: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree18.jpg",
    CottonSarees_19: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree19.jpg",
    CottonSarees_20: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree20.jpg",
    CottonSarees_21: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree21.jpg",
    CottonSarees_22: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree22.jpg",
    CottonSarees_23: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree23.jpg",
    CottonSarees_24: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree24.jpg",
    CottonSarees_25: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree25.jpg",
    CottonSarees_26: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree26.jpg",
    CottonSarees_27: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree27.jpg",
    CottonSarees_28: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree28.jpg",
    CottonSarees_29: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree29.jpg",
    CottonSarees_30: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree30.jpg",
    CottonSarees_31: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree31.jpg",
    CottonSarees_32: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree32.jpg",
    CottonSarees_33: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree33.jpg",
    CottonSarees_34: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree34.jpg",
    CottonSarees_35: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree35.jpg",
    CottonSarees_36: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree36.jpg",
    CottonSarees_37: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree37.jpg",
    CottonSarees_38: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree38.jpg",
    CottonSarees_39: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree39.jpg",
    CottonSarees_40: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree40.jpg",
    CottonSarees_41: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree41.jpg",
    CottonSarees_42: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree42.jpg",
    CottonSarees_43: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree43.jpg",
    CottonSarees_44: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree44.jpg",
    CottonSarees_45: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree45.jpg",
    CottonSarees_46: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree46.jpg",
    CottonSarees_47: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree47.jpg",
    CottonSarees_48: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree48.jpg",
    CottonSarees_49: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree49.jpg",
    CottonSarees_50: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree50.jpg",
    CottonSarees_51: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree51.jpg",
    CottonSarees_52: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree52.jpg",
    CottonSarees_53: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Saree53.jpg",
    Mukizh_Chettinad_Cotton_Saree1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree1.jpg",
    Mukizh_Chettinad_Cotton_Saree2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree2.jpg",
    Mukizh_Chettinad_Cotton_Saree3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree3.jpg",
    Mukizh_Chettinad_Cotton_Saree4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree4.jpg",
    Mukizh_Chettinad_Cotton_Saree5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree5.jpg",
    Mukizh_Chettinad_Cotton_Saree6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree6.jpg",
    Mukizh_Chettinad_Cotton_Saree7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree7.jpg",
    Mukizh_Chettinad_Cotton_Saree8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree8.jpg",
    Mukizh_Chettinad_Cotton_Saree9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree9.jpg",
    Mukizh_Chettinad_Cotton_Saree10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree10.jpg",
    Mukizh_Chettinad_Cotton_Saree11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree11.jpg",
    Mukizh_Chettinad_Cotton_Saree12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree12.jpg",
    Mukizh_Chettinad_Cotton_Saree13: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree13.jpg",
    Mukizh_Chettinad_Cotton_Saree14: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree14.jpg",
    Mukizh_Chettinad_Cotton_Saree15: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree15.jpg",
    Mukizh_Chettinad_Cotton_Saree16: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree16.jpg",
    Mukizh_Chettinad_Cotton_Saree17: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree17.jpg",
    Mukizh_Chettinad_Cotton_Saree18: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree18.jpg",
    Mukizh_Chettinad_Cotton_Saree19: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree19.jpg",
    Mukizh_Chettinad_Cotton_Saree20: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree20.jpg",
    Mukizh_Chettinad_Cotton_Saree21: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree21.jpg",
    Mukizh_Chettinad_Cotton_Saree22: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree22.jpg",
    Mukizh_Chettinad_Cotton_Saree23: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree23.jpg",
    Mukizh_Chettinad_Cotton_Saree24: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree24.jpg",
    Mukizh_Chettinad_Cotton_Saree25: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree25.jpg",
    Mukizh_Chettinad_Cotton_Saree26: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree26.jpg",
    Mukizh_Chettinad_Cotton_Saree27: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree27.jpg",
    Mukizh_Chettinad_Cotton_Saree28: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree28.jpg",
    Mukizh_Chettinad_Cotton_Saree29: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree29.jpg",
    Mukizh_Chettinad_Cotton_Saree30: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree30.jpg",
    Mukizh_Chettinad_Cotton_Saree31: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree31.jpg",
    Mukizh_Chettinad_Cotton_Saree32: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree32.jpg",
    Mukizh_Chettinad_Cotton_Saree33: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree33.jpg",
    Mukizh_Chettinad_Cotton_Saree34: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree34.jpg",
    Mukizh_Chettinad_Cotton_Saree35: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree35.jpg",
    Mukizh_Chettinad_Cotton_Saree36: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree36.jpg",
    Mukizh_Chettinad_Cotton_Saree37: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree37.jpg",
    Mukizh_Chettinad_Cotton_Saree38: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree38.jpg",
    Mukizh_Chettinad_Cotton_Saree39: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree39.jpg",
    Mukizh_Chettinad_Cotton_Saree40: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree40.jpg",
    Mukizh_Chettinad_Cotton_Saree41: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Chettinad-Cotton-Saree41.jpg",
    Mukizh_Soft_Silk_Saree1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Soft-Silk-Saree1.jpg",
    Mukizh_Soft_Silk_Saree2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Soft-Silk-Saree2.jpg",
    Mukizh_Soft_Silk_Saree3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Soft-Silk-Saree3.jpg",
    Mukizh_Soft_Silk_Saree4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Soft-Silk-Saree4.jpg",
    Mukizh_Soft_Silk_Saree5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Soft-Silk-Saree5.jpg",
    Mukizh_Soft_Silk_Saree6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Soft-Silk-Saree6.jpg",
    Mukizh_Soft_Silk_Saree7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Soft-Silk-Saree7.jpg",
    Mukizh_Soft_Silk_Saree8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Soft-Silk-Saree8.jpg",
    Mukizh_Soft_Silk_Saree9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Soft-Silk-Saree9.jpg",
    Mukizh_Soft_Silk_Saree10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Soft-Silk-Saree10.jpg",
    Mukizh_Soft_Silk_Saree11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Soft-Silk-Saree11.jpg",




    


    Synthetic_sarees_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_1.jpg",
    Synthetic_sarees_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_2.jpg",
    Synthetic_sarees_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_3.jpg",
    Synthetic_sarees_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_4.jpg",
    Synthetic_sarees_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_5.jpg",
    Synthetic_sarees_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_6.jpg",
    Synthetic_sarees_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_7.jpg",
    Synthetic_sarees_8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_8.jpg",
    Synthetic_sarees_9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_9.jpg",
    Synthetic_sarees_10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_10.jpg",
    Synthetic_sarees_11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_11.jpg",
    Synthetic_sarees_12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_12.jpg",
    Synthetic_sarees_13: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_13.jpg",
    Synthetic_sarees_14: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_14.jpg",
    Synthetic_sarees_15: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_15.jpg",
    Synthetic_sarees_16: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_16.jpg",
    Synthetic_sarees_17: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Synthetic_sarees_17.jpg",
    TusserSareeScreen_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/TusserSareeScreen_1.jpg",
    TusserSareeScreen_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/TusserSareeScreen_2.jpg",
    TusserSareeScreen_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/TusserSareeScreen_3.jpg",
    ZariWorkSareesScreen_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/ZariWorkSareesScreen_1.jpg",
    ZariWorkSareesScreen_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/ZariWorkSareesScreen_2.jpg",
    ZariWorkSareesScreen_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/ZariWorkSareesScreen_3.jpg",
    ZariWorkSareesScreen_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/ZariWorkSareesScreen_4.jpg",
    TopsansKurtisCotton_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/TopsansKurtisCotton_1.jpg",
    TopsansKurtisCotton_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/TopsansKurtisCotton_2.jpg",
    TopsansKurtisCotton_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/TopsansKurtisCotton_3.jpg",
    TopsansKurtisCotton_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/TopsansKurtisCotton_4.jpg",
    TopsansKurtisCotton_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/TopsansKurtisCotton_5.jpg",
    TopsansKurtisCotton_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/TopsansKurtisCotton_6.jpg",
    TopsansKurtisCotton_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/TopsansKurtisCotton_7.jpg",
    ChickencurryEmbroideryTops_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/ChickencurryEmbroideryTops_1.jpg",
    PureCottonTopsandKurtis_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/PureCottonTopsandKurtis_1.jpg",
    RayonTopsandKurtis_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/RayonTopsandKurtis_1.jpg",
    RayonTopsandKurtis_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/RayonTopsandKurtis_2.jpg",
    SyntheticTopsandKurtis_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/SyntheticTopsandKurtis_1.jpg",
    SyntheticTopsandKurtis_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/SyntheticTopsandKurtis_2.jpg",
    AnkleLengthLeggings_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/AnkleLengthLeggings_1.jpg",
    AnkleLengthLeggings_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/AnkleLengthLeggings_2.jpg",
    CigaretePants_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/CigaretePants_1.jpg",
    CigaretePants_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/CigaretePants_2.jpg",
    CigaretePants_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/CigaretePants_3.jpg",
    CigaretePants_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/CigaretePants_4.jpg",
    Jeggings_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Jeggings_1.jpg",
    ChuridarLeggings_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/ChuridarLeggings_1.jpg",
    ChuridarLeggings_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/ChuridarLeggings_2.jpg",
    ChuridarLeggings_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/ChuridarLeggings_3.jpg",
    ChuridarLeggings_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/ChuridarLeggings_4.jpg",
    PatialaPants_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/PatialaPants_1.jpg",
    PalazzoPants_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/PalazzoPants_1.jpg",
    PalazzoPants_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/PalazzoPants_2.jpg",
    PalazzoPants_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/PalazzoPants_3.jpg",
    PalazzoPants_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/PalazzoPants_4.jpg",
    PalazzoPants_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/PalazzoPants_5.jpg",
    PalazzoPants_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/PalazzoPants_6.jpg",
    PalazzoPants_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/PalazzoPants_7.jpg",
    PalazzoPants_8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/PalazzoPants_8.jpg",
    PalazzoPants_9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/PalazzoPants_9.jpg",
    SilkSarees_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/SilkSarees_1.jpg",
    SilkSarees_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/SilkSarees_2.jpg",
    SilkSarees_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/SilkSarees_3.jpg",
    SilkSarees_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/SilkSarees_4.jpg",
    SilkSarees_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/SilkSarees_5.jpg",
    SilkSarees_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/SilkSarees_6.jpg",
    SilkSarees_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/SilkSarees_7.jpg",
    SilkSarees_8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/SilkSarees_8.jpg",
    SilkSarees_9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/SilkSarees_9.jpg",
    SilkSarees_10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/SilkSarees_10.jpg",
    Churidar_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade1.png",
    Churidar_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade2.png",
    Churidar_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade3.png",
    Churidar_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade4.png",
    Churidar_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade5.png",
    Churidar_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade6.png",
    Churidar_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade7.png",
    Churidar_8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade8.png",
    Churidar_9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade9.png",
    Churidar_10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade10.png",
    Churidar_11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade11.png",
    Churidar_12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade12.png",
    Churidar_13: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade13.png",
    Churidar_14: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade14.png",
    Churidar_15: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade15.jpg",
    Churidar_16: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade16.jpg",
    Churidar_17: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade17.jpg",
    Churidar_18: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade18.jpg",
    Churidar_19: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade19.jpg",
    Churidar_20: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade20.jpg",
    Churidar_21: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade21.jpg",
    Churidar_22: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade22.jpg",
    Churidar_23: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade23.jpg",
    Churidar_24: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade24.jpg",
    Churidar_25: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade25.jpg",
    Churidar_26: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade26.jpg",
    Churidar_27: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade27.jpg",
    Churidar_28: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade28.jpg",
    Churidar_29: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade29.jpg",
    Churidar_30: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade30.jpg",
    Churidar_31: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade31.jpg",
    Churidar_32: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade32.jpg",
    Churidar_33: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade33.jpg",
    Churidar_34: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade34.jpg",
    Churidar_35: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade35.jpg",
    Churidar_36: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade36.jpg",
    Churidar_37: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade37.jpg",
    Churidar_38: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade38.jpg",
    Churidar_39: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade39.jpg",
    Churidar_40: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade40.png",
    Churidar_41: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Churidar-Readymade41.png",

    kids_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_1.jpg",
    kids_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_2.jpg",
    kids_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_3.jpg",
    kids_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_4.jpg",
    kids_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_5.jpg",
    kids_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_6.jpg",
    kids_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_7.jpg",
    kids_8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_8.jpg",
    kids_9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_9.jpg",
    kids_10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_10.jpg",
    kids_11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_11.jpg",
    kids_12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_12.jpg",
    kids_13: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_13.jpg",
    kids_14: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_14.jpg",
    kids_15: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/kids_15.jpg",
    AnkleLeggingsPants_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/AnkleLeggingsPants_1.jpg",
    AnkleLeggingsPants_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/AnkleLeggingsPants_2.jpg",
    jeggings_pants_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/jeggings_pants_1.jpg",
    churidar_leggings_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/churidar_leggings_1.jpg",
    churidar_leggings_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/churidar_leggings_2.jpg",
    churidar_leggings_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/churidar_leggings_3.jpg",
    palazzopants_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/palazzopants_1.jpg",
    palazzopants_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/palazzopants_2.jpg",
    palazzopants_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/palazzopants_3.jpg",
    palazzopants_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/palazzopants_4.jpg",
    palazzopants_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/palazzopants_5.jpg",
    palazzopants_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/palazzopants_6.jpg",
    palazzopants_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/palazzopants_7.jpg",
    palazzopants_8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/palazzopants_8.jpg",
    palazzopants_9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/palazzopants_9.jpg",
    patiala_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/patiala_1.jpg",
    Pencil_Fit_Pants_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Pencil_Fit_Pants_1.jpg",
    Pencil_Fit_Pants_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Pencil_Fit_Pants_2.jpg",
    Pencil_Fit_Pants_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Pencil_Fit_Pants_3.jpg",
    Pencil_Fit_Pants_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Pencil_Fit_Pants_4.jpg",
    Tops_Cotton_1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis1.png",
    Tops_Cotton_2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis2.png",
    Tops_Cotton_3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis3.png",
    Tops_Cotton_4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis4.png",
    Tops_Cotton_5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis5.png",
    Tops_Cotton_6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis6.png",
    Tops_Cotton_7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis7.png",
    Tops_Cotton_8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis8.jpg",
    Tops_Cotton_9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis9.jpg",
    Tops_Cotton_10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis10.jpg",
    Tops_Cotton_11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis11.jpg",
    Tops_Cotton_12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis12.jpg",
    Tops_Cotton_13: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis13.jpg",
    Tops_Cotton_14: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis14.jpg",
    Tops_Cotton_15: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis15.jpg",
    Tops_Cotton_16: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis16.jpg",
    Tops_Cotton_17: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis17.jpg",
    Tops_Cotton_18: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis18.png",
    Tops_Cotton_19: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis19.png",
    Tops_Cotton_20: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis20.png",
    Tops_Cotton_21: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis21.png",
    Tops_Cotton_22: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis22.png",
    Tops_Cotton_23: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis23.png",
    Tops_Cotton_24: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis24.png",
    Tops_Cotton_25: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis25.png",
    Tops_Cotton_26: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis26.png",
    Tops_Cotton_27: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Tops&Kurtis27.png",
    Mukizh_Rayon_Nightwear1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Rayon-Nightwear1.png",
    Mukizh_Rayon_Nightwear2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Rayon-Nightwear2.png",
    Mukizh_Rayon_Nightwear3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Rayon-Nightwear3.png",
    Mukizh_Rayon_Nightwear4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Rayon-Nightwear4.png",
    Mukizh_Rayon_Nightwear5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Rayon-Nightwear5.png",



    Mukizh_Bangles1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bangles1.jpg",
    Mukizh_Bangles2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bangles2.jpg",
    Mukizh_Bangles3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bangles3.jpg",
    Mukizh_Bangles4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bangles4.jpg",
    Mukizh_Bangles5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bangles5.jpg",
    Mukizh_Bangles6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bangles6.jpg",
    Mukizh_Bangles7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bangles7.jpg",
    Mukizh_Bangles8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bangles8.jpg",
    Mukizh_Bangles9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bangles9.jpg",
    Mukizh_Bindis1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bindis1.jpg",
    Mukizh_Bindis2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bindis2.jpg",
    Mukizh_Bindis3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bindis3.jpg",
    Mukizh_Bindis4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bindis4.jpg",
    Mukizh_Bindis5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bindis5.jpg",
    Mukizh_Bindis6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bindis6.jpg",
    Mukizh_Bindis7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bindis7.jpg",
    Mukizh_Bindis8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bindis8.jpg",
    Mukizh_Bindis9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bindis9.jpg",
    Mukizh_Bindis10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bindis10.jpg",
    Mukizh_Bindis11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bindis11.jpg",
    Mukizh_Bindis12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bindis12.jpg",
    Mukizh_Blouse_Beads1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads1.jpg",
    Mukizh_Blouse_Beads2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads2.jpg",
    Mukizh_Blouse_Beads3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads3.jpg",
    Mukizh_Blouse_Beads4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads4.jpg",
    Mukizh_Blouse_Beads5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads5.jpg",
    Mukizh_Blouse_Beads6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads6.jpg",
    Mukizh_Blouse_Beads7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads7.jpg",
    Mukizh_Blouse_Beads8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads8.jpg",
    Mukizh_Blouse_Beads9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads9.jpg",
    Mukizh_Blouse_Beads10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads10.jpg",
    Mukizh_Blouse_Beads11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads11.jpg",
    Mukizh_Blouse_Beads12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads12.jpg",
    Mukizh_Blouse_Beads13: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads13.jpg",
    Mukizh_Blouse_Beads14: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads14.jpg",
    Mukizh_Blouse_Beads15: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads15.jpg",
    Mukizh_Blouse_Beads16: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads16.jpg",
    Mukizh_Blouse_Beads17: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads17.jpg",
    Mukizh_Blouse_Beads18: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads18.jpg",
    Mukizh_Blouse_Beads19: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads19.jpg",
    Mukizh_Blouse_Beads20: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads20.jpg",
    Mukizh_Blouse_Beads21: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads21.jpg",
    Mukizh_Blouse_Beads22: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads22.jpg",
    Mukizh_Blouse_Beads23: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads23.jpg",
    Mukizh_Blouse_Beads24: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads24.jpg",
    Mukizh_Blouse_Beads25: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads25.jpg",
    Mukizh_Blouse_Beads26: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads26.jpg",
    Mukizh_Blouse_Beads27: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads27.jpg",
    Mukizh_Blouse_Beads28: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads28.jpg",
    Mukizh_Blouse_Beads29: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads29.jpg",
    Mukizh_Blouse_Beads30: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads30.jpg",
    Mukizh_Blouse_Beads31: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads31.jpg",
    Mukizh_Blouse_Beads32: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads32.jpg",
    Mukizh_Blouse_Beads33: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Blouse-Beads33.jpg",
    Mukizh_Earrings1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Earrings1.jpg",
    Mukizh_Earrings2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Earrings2.jpg",
    Mukizh_Earrings3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Earrings3.jpg",
    Mukizh_Earrings4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Earrings4.jpg",
    Mukizh_Earrings5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Earrings5.jpg",
    Mukizh_Hair_Clip1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Hair-Clip1.jpg",
    Mukizh_Hair_Clip2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Hair-Clip2.jpg",
    Mukizh_Hair_Clip3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Hair-Clip3.jpg",
    Mukizh_Hip_Belt1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Hip-Belt1.jpg",
    Mukizh_Hip_Belt2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Hip-Belt2.jpg",
    Mukizh_Hip_Belt3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Hip-Belt3.jpg",
    Mukizh_Hip_Belt4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Hip-Belt4.jpg",
    Mukizh_Hip_Belt5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Hip-Belt5.jpg",
    Mukizh_Nail_Polish1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Nail-Polish1.jpg",
    Mukizh_Nail_Polish2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Nail-Polish2.jpg",
    Mukizh_Nail_Polish3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Nail-Polish3.jpg",
    Mukizh_Nail_Polish4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Nail-Polish4.jpg",
    Mukizh_Nail_Polish5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Nail-Polish5.jpg",
    Mukizh_Necklaces1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces1.jpg",
    Mukizh_Necklaces2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces2.jpg",
    Mukizh_Necklaces3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces3.jpg",
    Mukizh_Necklaces4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces4.jpg",
    Mukizh_Necklaces5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces5.jpg",
    Mukizh_Necklaces6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces6.jpg",
    Mukizh_Necklaces7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces7.jpg",
    Mukizh_Necklaces8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces8.jpg",
    Mukizh_Necklaces9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces9.jpg",
    Mukizh_Necklaces10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces10.jpg",
    Mukizh_Necklaces11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces11.jpg",
    Mukizh_Necklaces12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces12.jpg",
    Mukizh_Necklaces13: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces13.jpg",
    Mukizh_Necklaces14: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces14.jpg",
    Mukizh_Necklaces15: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces15.jpg",
    Mukizh_Necklaces16: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces16.jpg",
    Mukizh_Necklaces17: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces17.jpg",
    Mukizh_Necklaces18: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces18.jpg",
    Mukizh_Necklaces19: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces19.jpg",
    Mukizh_Necklaces20: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces20.jpg",
    Mukizh_Necklaces21: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Necklaces21.jpg",
    Mukizh_Churidar_Material1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material1.jpg",
    Mukizh_Churidar_Material2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material2.jpg",
    Mukizh_Churidar_Material3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material3.jpg",
    Mukizh_Churidar_Material4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material4.jpg",
    Mukizh_Churidar_Material5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material5.jpg",
    Mukizh_Churidar_Material6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material6.jpg",
    Mukizh_Churidar_Material7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material7.jpg",
    Mukizh_Churidar_Material8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material8.jpg",
    Mukizh_Churidar_Material9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material9.jpg",
    Mukizh_Churidar_Material10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material10.jpg",
    Mukizh_Churidar_Material11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material11.jpg",
    Mukizh_Churidar_Material12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material12.jpg",
    Mukizh_Churidar_Material13: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material13.jpg",
    Mukizh_Churidar_Material14: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material14.jpg",
    Mukizh_Churidar_Material15: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material15.jpg",
    Mukizh_Churidar_Material16: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material16.jpg",
    Mukizh_Churidar_Material17: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Churidar-Material17.jpg",

    Mukizh_Cotton_Pant1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Pant1.jpg",
    Mukizh_Cotton_Pant2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Pant2.jpg",
    Mukizh_Cotton_Pant3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Pant3.jpg",
    Mukizh_Cotton_Pant4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Pant4.jpg",
    Mukizh_Cotton_Pant5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Pant5.jpg",
    Mukizh_Cotton_Pant6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Pant6.jpg",
    Mukizh_Cotton_Pant7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Pant7.jpg",
    Mukizh_Cotton_Pant8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Pant8.jpg",
    Mukizh_Cotton_Pant9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Pant9.jpg",
    Mukizh_Kidswear_Pant1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Kidswear-Pant1.jpg",
    Mukizh_Cotton_Nightwear1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Nightwear1.jpg",
    Mukizh_Cotton_Nightwear2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Nightwear2.jpg",
    Mukizh_Cotton_Nightwear3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Nightwear3.jpg",
    Mukizh_Cotton_Nightwear4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Nightwear4.jpg",
    Mukizh_Cotton_Nightwear5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Nightwear5.jpg",
    Mukizh_Cotton_Nightwear6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Nightwear6.jpg",
    Mukizh_Cotton_Nightwear7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Cotton-Nightwear7.jpg",
    Mukizh_A_Line_Nightwear1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear1.jpg",
    Mukizh_A_Line_Nightwear2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear2.jpg",
    Mukizh_A_Line_Nightwear3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear3.jpg",
    Mukizh_A_Line_Nightwear4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear4.jpg",
    Mukizh_A_Line_Nightwear5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear5.jpg",
    Mukizh_A_Line_Nightwear6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear6.jpg",
    Mukizh_A_Line_Nightwear7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear7.jpg",
    Mukizh_A_Line_Nightwear8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear8.jpg",
    Mukizh_A_Line_Nightwear9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear9.jpg",
    Mukizh_A_Line_Nightwear10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear10.jpg",
    Mukizh_A_Line_Nightwear11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear11.jpg",
    Mukizh_A_Line_Nightwear12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear12.jpg",
    Mukizh_A_Line_Nightwear13: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear13.jpg",
    Mukizh_A_Line_Nightwear14: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear14.jpg",
    Mukizh_A_Line_Nightwear15: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear15.jpg",
    Mukizh_A_Line_Nightwear16: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear16.jpg",
    Mukizh_A_Line_Nightwear17: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear17.jpg",
    Mukizh_A_Line_Nightwear18: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear18.jpg",
    Mukizh_A_Line_Nightwear19: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear19.jpg",
    Mukizh_A_Line_Nightwear20: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear20.jpg",
    Mukizh_A_Line_Nightwear21: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-A-Line-Nightwear21.jpg",



    Mukizh_Stole1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Stole1.jpg",
    Mukizh_Stole2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Stole2.jpg",
    Mukizh_Stole3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Stole3.jpg",
    Mukizh_Tussar_Saree1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Tussar-Saree1.png",
    Mukizh_Bandhini_Saree1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bandhini-Saree1.jpg",
    Mukizh_Bandhini_Saree2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bandhini-Saree2.jpg",
    Mukizh_Bandhini_Saree3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bandhini-Saree3.jpg",
    Mukizh_Bandhini_Saree4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bandhini-Saree4.jpg",
    Mukizh_Bandhini_Saree5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bandhini-Saree5.jpg",
    Mukizh_Bandhini_Saree6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bandhini-Saree6.jpg",
    Mukizh_Bandhini_Saree7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bandhini-Saree7.jpg",
    Mukizh_Bandhini_Saree8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bandhini-Saree8.jpg",
    Mukizh_Bandhini_Saree9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bandhini-Saree9.jpg",
    Mukizh_Bandhini_Saree10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bandhini-Saree10.jpg",
    Mukizh_Bandhini_Saree11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bandhini-Saree11.jpg",
    Mukizh_Bandhini_Saree12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Bandhini-Saree12.jpg",
    Mukizh_Silk_Cotton_Churidar_Material1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-SilkCotton-Churidar-Material1.jpg",
    Mukizh_Silk_Cotton_Churidar_Material2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-SilkCotton-Churidar-Material2.jpg",
    Mukizh_Silk_Cotton_Churidar_Material3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-SilkCotton-Churidar-Material3.jpg",
    Mukizh_Silk_Cotton_Churidar_Material4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-SilkCotton-Churidar-Material4.jpg",
    Mukizh_Silk_Cotton_Churidar_Material5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-SilkCotton-Churidar-Material5.jpg",
    Mukizh_Silk_Cotton_Churidar_Material6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-SilkCotton-Churidar-Material6.jpg",
    Mukizh_Silk_Cotton_Churidar_Material7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-SilkCotton-Churidar-Material7.jpg",
    Mukizh_Silk_Cotton_Churidar_Material8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-SilkCotton-Churidar-Material8.jpg",
    Mukizh_Silk_Cotton_Churidar_Material9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-SilkCotton-Churidar-Material9.jpg",
    Mukizh_Silk_Cotton_Churidar_Material10: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Silk-Cotton-Churidar-Material10.jpg",
    Mukizh_Silk_Cotton_Churidar_Material11: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Silk-Cotton-Churidar-Material11.jpg",
    Mukizh_Silk_Cotton_Churidar_Material12: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Silk-Cotton-Churidar-Material12.jpg",
    Mukizh_Silk_Cotton_Churidar_Material13: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Silk-Cotton-Churidar-Material13.jpg",
    Mukizh_Silk_Cotton_Churidar_Material14: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Silk-Cotton-Churidar-Material14.jpg",
    Mukizh_Silk_Cotton_Churidar_Material15: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Silk-Cotton-Churidar-Material15.jpg",
    Mukizh_Handbag1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Handbag1.jpg",
    Mukizh_Handbag2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Handbag2.jpg",
    Mukizh_Handbag3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Handbag3.jpg",
    Mukizh_Handbag4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Handbag4.jpg",
    Mukizh_Handbag5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Handbag5.jpg",
    Mukizh_Handbag6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Handbag6.jpg",
    Mukizh_Handbag7: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Handbag7.jpg",
    Mukizh_Handbag8: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Handbag8.jpg",
    Mukizh_Handbag9: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Handbag9.jpg",
    Mukizh_Purse1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Purse1.jpg",
    Mukizh_Purse2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Purse2.jpg",
    Mukizh_Purse3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Purse3.jpg",
    Mukizh_Purse4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Purse4.jpg",
    Mukizh_Purse5: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Purse5.jpg",
    Mukizh_Purse6: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Purse6.jpg",
    Mukizh_Imported_Erasers_Sharpners1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Imported-Erasers&Sharpners1.jpg",
    Mukizh_Imported_Pencil_Box1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Imported-Pencil-Box1.jpg",
    Mukizh_Imported_Pencil_Box2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Imported-Pencil-Box2.jpg",
    Mukizh_Imported_Pencil_Box3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Imported-Pencil-Box3.jpg",
    Mukizh_Imported_Pencil_Pouch1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Imported-Pencil-Pouch1.jpg",
    Mukizh_Imported_Pencil_Pouch2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Imported-Pencil-Pouch2.jpg",
    Mukizh_Imported_Pencil_Pouch3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Imported-Pencil-Pouch3.jpg",
    Mukizh_Imported_Pencil1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Imported-Pencil1.jpg",
    Mukizh_Imported_Pencil2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Imported-Pencil2.jpg",
    Mukizh_Imported_Pencil3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Imported-Pencil3.jpg",
    Mukizh_Imported_Pen1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Imported-Pen1.jpg",
    Mukizh_Imported_Pen2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Imported-Pen2.jpg",
    Mukizh_Imported_Pen3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Imported-Pen3.jpg",
    Mukizh_Dreamer_Doll_Keychain1: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Dreamer-Doll-Keychain1.jpg",
    Mukizh_Dreamer_Doll_Keychain2: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Dreamer-Doll-Keychain2.jpg",
    Mukizh_Dreamer_Doll_Keychain3: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Dreamer-Doll-Keychain3.jpg",
    Mukizh_Dreamer_Doll_Keychain4: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Dreamer-Doll-Keychain4.jpg",
    Mukizh_Baby_Doll_Keychain: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Baby-Doll-Keychain.jpg",
    Mukizh_Captain_America_Keychain: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Captain-America-Keychain.jpg",
    Mukizh_Dream_Catcher_Keychain: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Dream-Catcher-Keychain.jpg",
    Mukizh_House_Keychain: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-House-Keychain.jpg",
    Mukizh_Iron_Man_Keychain: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Iron-Man-Keychain.jpg",
    Mukizh_Lime_Drink_Keychain: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Lime-Drink-Keychain.jpg",
    Mukizh_Puppy_Keychains: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Puppy-Keychains.jpg",
    Mukizh_Rainbow_Keychain: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Rainbow-Keychain.jpg",
    Mukizh_Santa_Keychain: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Santa-Keychain.jpg",
    Mukizh_Teddy_Keychain: "https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Teddy-Keychain.jpg",
    Mukizh_Pochampalli_Silk_Saree1:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree1.jpg",
    Mukizh_Pochampalli_Silk_Saree2:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree2.jpg",
    Mukizh_Pochampalli_Silk_Saree3:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree3.jpg",
    Mukizh_Pochampalli_Silk_Saree4:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree4.jpg",
    Mukizh_Pochampalli_Silk_Saree5:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree5.jpg",
    Mukizh_Pochampalli_Silk_Saree6:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree6.jpg",
    Mukizh_Pochampalli_Silk_Saree7:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree7.jpg",
    Mukizh_Pochampalli_Silk_Saree8:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree8.jpg",
    Mukizh_Pochampalli_Silk_Saree9:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree9.jpg",
    Mukizh_Pochampalli_Silk_Saree10:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree10.jpg",
    Mukizh_Pochampalli_Silk_Saree11:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree11.jpg",
    Mukizh_Pochampalli_Silk_Saree12:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree12.jpg",
    Mukizh_Pochampalli_Silk_Saree13:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree13.jpg",
    Mukizh_Pochampalli_Silk_Saree14:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree14.jpg",
    Mukizh_Pochampalli_Silk_Saree15:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree15.jpg",
    Mukizh_Pochampalli_Silk_Saree16:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree16.jpg",
    Mukizh_Pochampalli_Silk_Saree17:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree17.jpg",
    Mukizh_Pochampalli_Silk_Saree18:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree18.jpg",
    Mukizh_Pochampalli_Silk_Saree19:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Pochampalli-Silk-Saree19.jpg",
    Mukizh_Western_Wear1:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Western-Wear1.jpg",
    Mukizh_Western_Wear2:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Western-Wear2.jpg",
    Mukizh_Western_Wear3:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Western-Wear3.jpg",
    Mukizh_Western_Wear4:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Western-Wear4.jpg",
    Mukizh_Western_Wear5:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Western-Wear5.jpg",
    Mukizh_Western_Wear6:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Western-Wear6.jpg",
    Mukizh_Western_Wear7:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Western-Wear7.jpg",
    Mukizh_Western_Wear8:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Western-Wear8.jpg",
    Mukizh_Western_Wear9:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Western-Wear9.jpg",
    Mukizh_Western_Wear10:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Western-Wear10.jpg",
    Mukizh_Western_Wear11:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Western-Wear11.jpg",
    Mukizh_Western_Wear12:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Western-Wear12.jpg",
    Mukizh_Western_Wear13:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Western-Wear13.jpg",
    Mukizh_Western_Wear14:"https://d2xkzwdjqw8hyf.cloudfront.net/Images/Mukizh-Western-Wear14.jpg",
    

    

    

    

    

    


















}
export default AppImages;