import { Grid } from '@mui/material';
import React, { useState } from 'react';
import AppPagiantion from '../../../GlobalComponents/AppPagiantion';
import ImageListComponent from '../../../GlobalComponents/ImageListComponent';
import AppImages from '../../../Globals/AppImages';
import AppModalComponent from '../../../Globals/AppModalComponent';
import { scrollToTop } from '../../../Globals/Helpers';

export default function EmbroiderySareesScreens(props: any) {

    const pic_details = [
        {
            id: 0,
            imgUrl: AppImages.EmbroiderySarees_1,
        },
        {
            id: 1,
            imgUrl: AppImages.EmbroiderySarees_2,
        },
        {
            id: 2,
            imgUrl: AppImages.EmbroiderySarees_3,
        },
        {
            id: 3,
            imgUrl: AppImages.EmbroiderySarees_4,
        },
        {
            id: 4,
            imgUrl: AppImages.EmbroiderySarees_5,
        },
        {
            id: 5,
            imgUrl: AppImages.EmbroiderySarees_6,
        },
        {
            id: 6,
            imgUrl: AppImages.EmbroiderySarees_7,
        },
        {
            id: 7,
            imgUrl: AppImages.EmbroiderySarees_8,
        },
        {
            id: 8,
            imgUrl: AppImages.EmbroiderySarees_9,
        },  {
            id: 9,
            imgUrl: AppImages.EmbroiderySarees_10,
        },  {
            id: 10,
            imgUrl: AppImages.EmbroiderySarees_11,
        },  {
            id: 11,
            imgUrl: AppImages.EmbroiderySarees_12,
        },



    ];

    const [open, setOpen] = useState(false);
    const [value, setvalue] = useState<any>(0);

    const handleOpen = (item: any) => {
        setvalue(item?.id)
        setOpen(!open)
    }
    const firstIndex = 0;
    const [pageSize, setPageSize] = useState(12);
    const [page, setPage] = useState(1);
    const [data, setData] = useState(pic_details.slice(firstIndex, pageSize));
    const handleChange = (event: any, value: any) => {
        setPage(value);
        setData(pic_details.slice(firstIndex + pageSize * (value - 1), pageSize * value));
        scrollToTop()

    };
    return (
        <div>
            <div className='grid-Outer'>
                <Grid container spacing={2}>
                    {(data || []).map((item: any) =>
                        <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                            <div onClick={() => handleOpen(item)}>
                                <ImageListComponent
                                    imgUrl={item?.imgUrl}
                                />
                            </div>
                        </Grid>
                    )}
                </Grid>

                <AppModalComponent
                    open={open}
                    setOpen={setOpen}
                    pic_details={pic_details}
                    value={value}
                />


            </div>
            <AppPagiantion
                handleChange={handleChange}
                pageSize={pageSize}
                pic_details={pic_details}
                page={page}
            />
        </div>

    );
}
