import { Typography } from '@mui/material';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AppBarMenu from '../../GlobalComponents/AppBarMenu';
import ContactComponent from '../../GlobalComponents/ContactComponent';
import AppConstants from '../../Globals/Appconstants';
import CategoryContainer from './CategoryContainer';
import ProductDetailsView from './ProductDetailsView';
import SubCategoryContainer from './SubCategoryContainer';

function ChuditharReadymadesScreen() {

    const subCategoryList = [
        {
            id: 0,
            name: AppConstants.subCategoriesChuditharReadyMades[0],
            key: AppConstants.subCategoriesChuditharReadyMades[0],
            pathName:"cotton-readymades"
        },
        {
            id: 1,
            name: AppConstants.subCategoriesChuditharReadyMades[1],
            key: AppConstants.subCategoriesChuditharReadyMades[1],
            pathName:"synthetic-fibre"
        },
        // {
        //     id: 2,
        //     name: AppConstants.subCategoriesChuditharReadyMades[2],
        //     key: AppConstants.subCategoriesChuditharReadyMades[2],
        //     pathName:"georgette"
        // },
        {
            id: 2,
            name: AppConstants.subCategoriesChuditharReadyMades[2],
            key: AppConstants.subCategoriesChuditharReadyMades[2],
            pathName:"silk-cotton"
        },
        // {
        //     id: 4,
        //     name: AppConstants.subCategoriesChuditharReadyMades[4],
        //     key: AppConstants.subCategoriesChuditharReadyMades[4],
        //     pathName:"embroidery"
        // },
        {
            id: 3,
            name: AppConstants.subCategoriesChuditharReadyMades[3],
            key: AppConstants.subCategoriesChuditharReadyMades[3],
            pathName:"chickencurry-embroidery"
        },
        {
            id: 4,
            name: AppConstants.subCategoriesChuditharReadyMades[4],
            key: AppConstants.subCategoriesChuditharReadyMades[4],
            pathName:"rayon"
        },
    ]
    const [tabValue, setTabValue] = useState<Number>(getTabValue());
    function getTabValue(): Number {
        let pathName = window.location.pathname.split("/");
        let path: any = subCategoryList.find((x: any) => x.pathName === pathName[2]);
        let id: Number = path ? path.id : 0;
        return id;
    }
    return (
        <AppBarMenu>
            <CategoryContainer
                tabValue={1}>
                <ProductDetailsView
                    title={AppConstants.chuditharReadymades}
                    description={AppConstants.chuditharReadymadesDesc} />
                <SubCategoryContainer
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    subCategoryList={subCategoryList}>
                    <Outlet />
                </SubCategoryContainer>
                <ContactComponent/>

            </CategoryContainer>
        </AppBarMenu>
    );
}

export default ChuditharReadymadesScreen;