import { Grid, ImageList } from '@mui/material';
import React from 'react';
import ImageListComponent from '../../../GlobalComponents/ImageListComponent';
import AppImages from '../../../Globals/AppImages';

export default function SyntheticFibresChuditharReadymades(props: any) {
    // const pic_details = [
    //     {
    //         id: 0,
    //         imgUrl: AppImages.chuditharReadymades,
    //         imageName: "one",
    //         pre_Amt: 1000,
    //         cur_Amt: 500,
    //         discount: 1,
    //     },
    //     {
    //         id: 1,
    //         imgUrl: AppImages.chuditharReadymades,
    //         imageName: "one",
    //         pre_Amt: 1000,
    //         cur_Amt: 500,
    //         discount: 1,
    //     },
    // ];
    return (
        // <Grid container spacing={2}>
        //     {(pic_details || []).map((item: any) =>
        //             <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
        //             <ImageListComponent
        //                 imgUrl={item?.imgUrl}
        //                 imageName={item?.imageName}
        //                 pre_Amt={item?.pre_Amt}
        //                 cur_Amt={item?.cur_Amt}
        //                 discount={item?.discount}
        //             />
        //         </Grid>

        //     )}
        // </Grid>
        <></>
    );
}
