import { Backdrop, Box, Button, Fade, Grid, ImageList, Modal, Pagination, Typography } from '@mui/material';
import React, { useState } from 'react';
import AppPagiantion from '../../../GlobalComponents/AppPagiantion';
import ImageListComponent from '../../../GlobalComponents/ImageListComponent';
import AppImages from '../../../Globals/AppImages';
import ReactPaginate from "react-paginate";
import KeyboardArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import { ArrowLeftOutlined } from '@mui/icons-material';
import CustomizedButtons from '../../../Globals/Appbuttons';
import AppModalComponent from '../../../Globals/AppModalComponent';
import { scrollToTop } from '../../../Globals/Helpers';
interface CopperScreenProps {
}
export default function CottonScreen(props: CopperScreenProps) {
    const pic_details = [
        {
            id: 0,
            imgUrl: AppImages.CottonSarees_1,
        },
        {
            id: 1,
            imgUrl: AppImages.CottonSarees_2,
        },
        {
            id: 2,
            imgUrl: AppImages.CottonSarees_3,
        },
        {
            id: 3,
            imgUrl: AppImages.CottonSarees_4,
        },
        {
            id: 4,
            imgUrl: AppImages.CottonSarees_5,
        },
        {
            id: 5,
            imgUrl: AppImages.CottonSarees_6,
        }, {
            id: 6,
            imgUrl: AppImages.CottonSarees_7,
        },
        {
            id: 7,
            imgUrl: AppImages.CottonSarees_8,
        },
        {
            id: 8,
            imgUrl: AppImages.CottonSarees_9,
        }, {
            id: 9,
            imgUrl: AppImages.CottonSarees_10,
        }, {
            id: 10,
            imgUrl: AppImages.CottonSarees_11,
        }, {
            id: 11,
            imgUrl: AppImages.CottonSarees_12,
        }, {
            id: 12,
            imgUrl: AppImages.CottonSarees_13,
        }, {
            id: 13,
            imgUrl: AppImages.CottonSarees_14,
        }, {
            id: 14,
            imgUrl: AppImages.CottonSarees_15,
        }, {
            id: 15,
            imgUrl: AppImages.CottonSarees_16,
        }, {
            id: 16,
            imgUrl: AppImages.CottonSarees_17,
        }, {
            id: 17,
            imgUrl: AppImages.CottonSarees_18,
        },
        {
            id: 18,
            imgUrl: AppImages.CottonSarees_19,
        },
        {
            id: 19,
            imgUrl: AppImages.CottonSarees_20,
        }, {
            id: 20,
            imgUrl: AppImages.CottonSarees_21,
        }, {
            id: 21,
            imgUrl: AppImages.CottonSarees_22,
        }, {
            id: 22,
            imgUrl: AppImages.CottonSarees_23,
        }, {
            id: 23,
            imgUrl: AppImages.CottonSarees_24,
        }, {
            id: 24,
            imgUrl: AppImages.CottonSarees_25,
        }, {
            id: 25,
            imgUrl: AppImages.CottonSarees_26,
        }, {
            id: 26,
            imgUrl: AppImages.CottonSarees_27,
        }, {
            id: 27,
            imgUrl: AppImages.CottonSarees_28,
        }, {
            id: 28,
            imgUrl: AppImages.CottonSarees_29,
        }, {
            id: 29,
            imgUrl: AppImages.CottonSarees_30,
        }, {
            id: 30,
            imgUrl: AppImages.CottonSarees_31,
        }, {
            id: 31,
            imgUrl: AppImages.CottonSarees_32,
        }, {
            id: 32,
            imgUrl: AppImages.CottonSarees_33,
        }, {
            id: 33,
            imgUrl: AppImages.CottonSarees_34,
        }, {
            id: 34,
            imgUrl: AppImages.CottonSarees_35,
        }, {
            id: 35,
            imgUrl: AppImages.CottonSarees_36,
        },
        {
            id: 36,
            imgUrl: AppImages.CottonSarees_37,
        }, {
            id: 37,
            imgUrl: AppImages.CottonSarees_38,
        }, {
            id: 38,
            imgUrl: AppImages.CottonSarees_39,
        }, {
            id: 39,
            imgUrl: AppImages.CottonSarees_40,
        }, {
            id: 40,
            imgUrl: AppImages.CottonSarees_41,
        }, {
            id: 41,
            imgUrl: AppImages.CottonSarees_42,
        }, {
            id: 42,
            imgUrl: AppImages.CottonSarees_43,
        }, {
            id: 43,
            imgUrl: AppImages.CottonSarees_44,
        }, {
            id: 44,
            imgUrl: AppImages.CottonSarees_45,
        }, {
            id: 45,
            imgUrl: AppImages.CottonSarees_46,
        }, {
            id: 46,
            imgUrl: AppImages.CottonSarees_47,
        }, {
            id: 47,
            imgUrl: AppImages.CottonSarees_48,
        }, {
            id: 48,
            imgUrl: AppImages.CottonSarees_49,
        }, {
            id: 49,
            imgUrl: AppImages.CottonSarees_50,
        }, {
            id: 50,
            imgUrl: AppImages.CottonSarees_51,
        }, {
            id: 51,
            imgUrl: AppImages.CottonSarees_52,
        }, {
            id: 52,
            imgUrl: AppImages.CottonSarees_53,
        },
        {
            id: 53,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree1,
        },
        {
            id: 54,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree2,
        },  {
            id: 55,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree3,
        },  {
            id: 56,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree4,
        },  {
            id: 57,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree5,
        },  {
            id: 58,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree6,
        },  {
            id: 59,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree7,
        },  {
            id: 60,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree8,
        },  {
            id: 61,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree9,
        },  {
            id: 62,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree10,
        },  {
            id: 63,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree11,
        },  {
            id: 64,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree12,
        },  {
            id: 65,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree13,
        },  {
            id: 66,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree14,
        },  {
            id: 67,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree15,
        },  {
            id: 68,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree16,
        },  {
            id: 69,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree17,
        },  {
            id: 70,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree18,
        },  {
            id: 71,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree19,
        },  {
            id: 72,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree20,
        },  {
            id: 73,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree21,
        },  {
            id: 74,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree22,
        },  {
            id: 75,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree23,
        },  {
            id: 76,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree24,
        },  {
            id: 77,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree25,
        },  {
            id: 78,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree26,
        },  {
            id: 79,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree27,
        },  {
            id: 80,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree28,
        },  {
            id: 81,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree29,
        },  {
            id: 82,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree30,
        },  {
            id: 83,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree31,
        },  {
            id: 84,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree32,
        },  {
            id: 85,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree33,
        },  {
            id: 86,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree34,
        },  {
            id: 87,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree35,
        },  {
            id: 88,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree36,
        },  {
            id: 89,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree37,
        },  {
            id: 90,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree38,
        },  {
            id: 91,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree39,
        },  {
            id: 92,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree40,
        },  {
            id: 93,
            imgUrl: AppImages.Mukizh_Chettinad_Cotton_Saree41,
        },


    ];
    const [open, setOpen] = useState(false);
    const [value, setvalue] = useState<any>(0);

    const handleOpen = (item: any) => {
        setvalue(item?.id)
        setOpen(!open)
    }

    const firstIndex = 0;
    const [pageSize, setPageSize] = useState(12);
    const [page, setPage] = useState(1);
    const [data, setData] = useState(pic_details.slice(firstIndex, pageSize));
    const handleChange = (event: any, value: any) => {
        setPage(value);
        setData(pic_details.slice(firstIndex + pageSize * (value - 1), pageSize * value));
        scrollToTop()

    };

    return (
        <div>
            <div className='grid-Outer'>
                <Grid container spacing={2}>
                    {(data || []).map((item: any) =>
                        <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                            <div onClick={() => handleOpen(item)}>
                                <ImageListComponent
                                    imgUrl={item?.imgUrl}
                                />
                            </div>
                        </Grid>
                    )}
                </Grid>

                <AppModalComponent
                    open={open}
                    setOpen={setOpen}
                    pic_details={pic_details}
                    value={value}
                />


            </div>
            <AppPagiantion
                handleChange={handleChange}
                pageSize={pageSize}
                pic_details={pic_details}
                page={page}
            />
        </div>
    );
}

