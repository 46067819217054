import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AppBarMenu from '../../GlobalComponents/AppBarMenu';
import ContactComponent from '../../GlobalComponents/ContactComponent';
import AppConstants from '../../Globals/Appconstants';
import CategoryContainer from './CategoryContainer';
import ProductDetailsView from './ProductDetailsView';
import SubCategoryContainer from './SubCategoryContainer';

export default function ChuditharMaterialsScreen(props: any) {

    const subCategoryList = [
        {
            id: 0,
            name: AppConstants.subCategoriesChuditharMaterials[0],
            key: AppConstants.subCategoriesChuditharMaterials[0],
            pathName: "cotton-materials"
        },
        // {
        //     id: 1,
        //     name: AppConstants.subCategoriesChuditharMaterials[1],
        //     key: AppConstants.subCategoriesChuditharMaterials[1],
        //     pathName: "synthetic-fibre"
        // },
        // {
        //     id: 2,
        //     name: AppConstants.subCategoriesChuditharMaterials[2],
        //     key: AppConstants.subCategoriesChuditharMaterials[2],
        //     pathName: "georgette"
        // },
        {
            id: 1,
            name: AppConstants.subCategoriesChuditharMaterials[1],
            key: AppConstants.subCategoriesChuditharMaterials[1],
            pathName: "embroidery"
        },
        {
            id: 2,
            name: AppConstants.subCategoriesChuditharMaterials[2],
            key: AppConstants.subCategoriesChuditharMaterials[2],
            pathName: "silk-cotton"
        },
    ]
    const [tabValue, setTabValue] = useState<Number>(getTabValue());
    function getTabValue(): Number {
        let pathName = window.location.pathname.split("/");
        let path: any = (subCategoryList || []).find((x: any) => x.pathName === pathName[2]);
        let id: Number = path ? path.id : 0;
        return id;
    }
    return (
        <AppBarMenu>
            <CategoryContainer
                tabValue={2}>
                <ProductDetailsView
                    title={AppConstants.chuditharMaterial}
                    description={AppConstants.chuditharMaterialDesc} />
                <SubCategoryContainer
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    subCategoryList={subCategoryList}>
                    <Outlet />
                </SubCategoryContainer>
            </CategoryContainer>
            <ContactComponent/>

        </AppBarMenu>
    );
}
