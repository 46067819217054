import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AppBarMenu from '../../GlobalComponents/AppBarMenu';
import ContactComponent from '../../GlobalComponents/ContactComponent';
import AppConstants from '../../Globals/Appconstants';
import CategoryContainer from './CategoryContainer';
import ProductDetailsView from './ProductDetailsView';
import SubCategoryContainer from './SubCategoryContainer';

const subCategoryList = [
  
    {
        id: 0,
        name: AppConstants.subCategoriesSarees[0],
        key: AppConstants.subCategoriesSarees[0],
        pathName: "cotton-sarees"
    },
    // {
    //     id: 1,
    //     name: AppConstants.subCategoriesSarees[1],
    //     key: AppConstants.subCategoriesSarees[1],
    //     pathName: "synthetic-sarees"
    // },
    {
        id: 1,
        name: AppConstants.subCategoriesSarees[1],
        key: AppConstants.subCategoriesSarees[1],
        pathName: "tussar-sarees"

    },
    {
        id: 2,
        name: AppConstants.subCategoriesSarees[2],
        key: AppConstants.subCategoriesSarees[2],
        pathName: "banarasi-sarees"

    },
    {
        id: 3,
        name: AppConstants.subCategoriesSarees[3],
        key: AppConstants.subCategoriesSarees[3],
        pathName: "embroidery-sarees"

    },
    // {
    //     id: 5,
    //     name: AppConstants.subCategoriesSarees[5],
    //     key: AppConstants.subCategoriesSarees[5],
    //     pathName: "zari-work-sarees"

    // },
    // {
    //     id: 6,
    //     name: AppConstants.subCategoriesSarees[6],
    //     key: AppConstants.subCategoriesSarees[6],
    //     pathName: "border-work-sarees"

    // },
    {
        id: 4,
        name: AppConstants.subCategoriesSarees[4],
        key: AppConstants.subCategoriesSarees[4],
        pathName: "silk-sarees"
    },
    {
        id: 5,
        name: AppConstants.subCategoriesSarees[5],
        key: AppConstants.subCategoriesSarees[5],
        pathName: "soft-silk-sarees"

    },
    {
        id: 6,
        name: AppConstants.subCategoriesSarees[6],
        key: AppConstants.subCategoriesSarees[6],
        pathName: "poonam-sarees"
    },
    {
        id: 7,
        name: AppConstants.subCategoriesSarees[7],
        key: AppConstants.subCategoriesSarees[7],
        pathName: "designer-sarees"
    },
    {
        id: 8,
        name: AppConstants.subCategoriesSarees[8],
        key: AppConstants.subCategoriesSarees[8],
        pathName: "bandhini-sarees"
    },
]

function SareesScreen() {
    const [tabValue, setTabValue] = useState<Number>(getTabValue());

    function getTabValue(): Number {
        let pathName = window.location.pathname.split("/");
        let path: any = subCategoryList.find((x: any) => x.pathName === pathName[2]);
        let id: Number = path ? path.id : 0;
        return id;
    }

    return (
        <AppBarMenu>
            <CategoryContainer
                tabValue={0}>
                <ProductDetailsView
                    title={AppConstants.sarees}
                    description={AppConstants.sareesDesc} />
                <SubCategoryContainer
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    subCategoryList={subCategoryList}>
                    <Outlet />
                </SubCategoryContainer>
            </CategoryContainer>
            <ContactComponent/>
        </AppBarMenu>
    );
}

export default SareesScreen;