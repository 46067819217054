import React from 'react';
import './Index.scss';
import {
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Container,
    Avatar,
    Tooltip,
    MenuItem,
    Button,
    AppBar,
    Grid,
    Tab,
    Tabs
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import AppImages from '../Globals/AppImages';
import CustomizedButtons from '../Globals/Appbuttons';
import AppConstants from '../Globals/Appconstants';
import { Router, useNavigate } from 'react-router-dom';
const pages = ['Home', 'Products'];
const settings = ['Profile', 'Logout'];
export default function AppBarMenu(props: any) {
    const{
        keyId
    }=props
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const navigate = useNavigate();
    const [value, setValue] = React.useState(0);
console.log("keyId",keyId);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleOpenNavMenu = (event: any) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: any) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const gotoHome = () => {
        navigate("/home")
        scrollToTop()
    }
    const gotoProduct = () => {
        navigate("/sarees/cotton-sarees")
        scrollToTop()

    }
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }
    let pathName = window.location.pathname.split("/");
    return (
        <div>
            <AppBar
                position="fixed"
                className="Appbar-background"
            >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>
                        {/* <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                textDecoration: 'none',
                            }}
                        >
                            <img src={AppImages.AppLogo} />
                        </Typography> */}
                        <Box
                            sx={{
                                mr: 2,
                                display: { xs: 'none', md: 'flex' },
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                textDecoration: 'none',
                            }}
                        >
                            <img src={AppImages.AppLogo} />
                        </Box>

                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                className='icon-button-handburger'

                            // color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                <div className='menu-item-custom'>
                                    <MenuItem onClick={handleCloseNavMenu}
                                        className={keyId == 'home' ? "menu-home purple-class" : "menu-home"}
                                    >
                                        <Typography textAlign="center"
                                            onClick={() => gotoHome()}
                                        >Home</Typography>
                                    </MenuItem>
                                    <MenuItem onClick={handleCloseNavMenu}
                                        className={keyId != 'home' ? "menu-product purple-class " : "menu-product"}
                                    >
                                        <Typography textAlign="center"
                                            onClick={() => gotoProduct()}
                                        >Products</Typography>
                                    </MenuItem>
                                </div>
                            </Menu>
                        </Box>
                        {/* <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                // color: 'inherit',
                                textDecoration: 'none',

                            }}
                        >
                            <img src={AppImages.AppLogo} />
                        </Typography> */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <img src={AppImages.AppLogo} />
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>

                            <div className="Menu-Container" >
                                <div>
                                    <div className='div-Home'
                                        onClick={() => gotoHome()}
                                    >
                                        {keyId != 'home' ?
                                            <>
                                                <div className="home-img">
                                                    <img alt="" src={AppImages.HomeIcon} />
                                                </div>
                                                <div className="home-txt">
                                                    Home
                                                </div>
                                            </>
                                            :
                                            <>

                                                <div className="home-img">
                                                    <img alt="" src={AppImages.HomeBlackIcon} />
                                                </div>
                                                <div className="home-txt-home">
                                                    Home
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div >
                                    <div className='div-Products'
                                        onClick={() => gotoProduct()}
                                    >{keyId != 'home' ?
                                        <>
                                            <div className="Products-img">
                                                <img alt="" src={AppImages.ProductIcon} />
                                            </div>
                                            <div className="Products-txt"
                                            >
                                                Products
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="Products-img">
                                                <img alt="" src={AppImages.productpurple} />
                                            </div>
                                            <div className="Products-txt-product"
                                            >
                                                Products
                                            </div>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Box>
                        <CustomizedButtons
                            Text={AppConstants.contact_us}
                            fontClass={"font-Made-Tommy-Bold"}
                        />
                    </Toolbar>
                </Container>
            </AppBar>
            <Box sx={{
                mt: 12
            }}>{props.children}</Box>
        </div>
    );
}
