import {
    Tabs,
    Tab,
    Box,
    Typography
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppConstants from '../../Globals/Appconstants';
import "./Products.scss";

interface CategoryContainerProps {
    children: any,
    tabValue: number
}

interface TabPanelProps {
    children?: React.ReactNode;
    id: number;
    tabValue: number;
}


const categoryList = [
    {
        id: 0,
        name: AppConstants.sarees,
        key: "sarees"
    },
    {
        id: 1,
        name: AppConstants.chuditharReadymades,
        key: "chuditharReadymades"
    },
    {
        id: 2,
        name: AppConstants.chuditharMaterial,
        key: "chuditharMaterials"
    },
    {
        id: 3,
        name: AppConstants.topsandkurtis,
        key: "topsandkurtis"
    },
    {
        id: 4,
        name: AppConstants.pants,
        key: "pants"
    },
    {
        id: 5,
        name: AppConstants.kidswear,
        key: "kidswear"
    },
    {
        id: 6,
        name: AppConstants.jwelleryandaccessories,
        key: "jewelleryAndAccessories"
    },
    {
        id: 7,
        name: AppConstants.inskirt,
        key: "inskirt"
    },
    {
        id: 8,
        name: AppConstants.nightWear,
        key: "nightWear"
    },
    {
        id: 9,
        name: AppConstants.shawls,
        key: "shawls"
    }, {
        id: 10,
        name: AppConstants.stationery,
        key: "stationery"
    },
];


function CategoryContainer(props: CategoryContainerProps) {
    const {
        children,
        tabValue
    } = props;
    const navigate = useNavigate();
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        switch (newValue) {
            case 0:
                navigate("/sarees/cotton-sarees");
                break;
            case 1:
                navigate("/churidar-readymades/cotton-readymades");
                break;
            case 2:
                navigate("/churidar-materials/cotton-materials");
                break;
            case 3:
                navigate('/tops/cotton');
                break;
            case 4:
                navigate("/pants/cotton-pants");
                break;
            case 5:
                navigate("/kidswear/dresses");
                break;
            case 6:
                navigate("/jwellery-and-accessories/necklaces");
                break;
            case 7:
                navigate("/inskirt/cotton");
                break;
            case 8:
                navigate("/night-wear/cotton");
                break;
            case 9:
                navigate("/shawls/stoles");
                break;
            case 10:
                navigate("/stationery/imported-eraser&sharpners");
                break;
        }
    };

    function setProps(id: number) {
        return {
            id: `category-tab-${id}`,
            'aria-controls': `category-tabpanel-${id}`,
        };
    }

    function TabPanel(props: TabPanelProps) {
        const { children, tabValue, id, ...other } = props;
        return (
            <div
                role="categorytabpanel"
                hidden={tabValue !== id}
                id={`category-tabpanel-${id}`}
                aria-labelledby={`category-tab-${id}`}
                {...other} >
                {tabValue === id && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    return (
        <div>
            <Box
                sx={{ mx: 3 }}
            >
                <Tabs
                    className="category-tab-custom"
                    value={tabValue}
                    onChange={handleChange}
                    // aria-label="category tabs"
                    scrollButtons
                    allowScrollButtonsMobile
                    variant="scrollable"
                    aria-label="scrollable auto tabs example"
                    centered>
                    {(categoryList || []).map((item: any, index: any) => (
                        <Tab
                            className="tab-custom"
                            key={item.key}
                            label={item?.name}
                            {...setProps(item.id)} />
                    ))}
                </Tabs>
            </Box>
            {(categoryList || []).map((category: any) => {
                return (
                    <TabPanel
                        key={category.key}
                        tabValue={tabValue}
                        id={category.id}>
                        {children}
                    </TabPanel>
                )
            })}
        </div>
    );
}

export default CategoryContainer;