import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AppBarMenu from '../../GlobalComponents/AppBarMenu';
import ContactComponent from '../../GlobalComponents/ContactComponent';
import AppConstants from '../../Globals/Appconstants';
import CategoryContainer from './CategoryContainer';
import ProductDetailsView from './ProductDetailsView';
import SubCategoryContainer from './SubCategoryContainer';

export default function JwelleryandAccessoriesScreen(props: any) {
    const subCategoryList = [
        {
            id: 0,
            name: AppConstants.subCategoriesJwelleryandAccessories[0],
            key: AppConstants.subCategoriesJwelleryandAccessories[0],
            pathName: "necklaces"
        },
        {
            id: 1,
            name: AppConstants.subCategoriesJwelleryandAccessories[1],
            key: AppConstants.subCategoriesJwelleryandAccessories[1],
            pathName: "earrings"
        },
        {
            id: 2,
            name: AppConstants.subCategoriesJwelleryandAccessories[2],
            key: AppConstants.subCategoriesJwelleryandAccessories[2],
            pathName: "bangles"
        },
        {
            id: 3,
            name: AppConstants.subCategoriesJwelleryandAccessories[3],
            key: AppConstants.subCategoriesJwelleryandAccessories[3],
            pathName: "hairclips"
        },

        {
            id: 4,
            name: AppConstants.subCategoriesJwelleryandAccessories[4],
            key: AppConstants.subCategoriesJwelleryandAccessories[4],
            pathName: "hairbands"

        },
        {
            id: 5,
            name: AppConstants.subCategoriesJwelleryandAccessories[5],
            key: AppConstants.subCategoriesJwelleryandAccessories[5],
            pathName: "bindis"

        },
        {
            id: 6,
            name: AppConstants.subCategoriesJwelleryandAccessories[6],
            key: AppConstants.subCategoriesJwelleryandAccessories[6],
            pathName: "nailpolish"

        },
        {
            id: 7,
            name: AppConstants.subCategoriesJwelleryandAccessories[7],
            key: AppConstants.subCategoriesJwelleryandAccessories[7],
            pathName: "anklets"

        },
        {
            id: 8,
            name: AppConstants.subCategoriesJwelleryandAccessories[8],
            key: AppConstants.subCategoriesJwelleryandAccessories[8],
            pathName: "nethichutti"

        },
        {
            id: 9,
            name: AppConstants.subCategoriesJwelleryandAccessories[9],
            key: AppConstants.subCategoriesJwelleryandAccessories[9],
            pathName: "saree-pins"

        },
        {
            id: 10,
            name: AppConstants.subCategoriesJwelleryandAccessories[10],
            key: AppConstants.subCategoriesJwelleryandAccessories[10],
            pathName: "hip-belt"

        }, {
            id: 11,
            name: AppConstants.subCategoriesJwelleryandAccessories[11],
            key: AppConstants.subCategoriesJwelleryandAccessories[11],
            pathName: "blouse-tassel-beads"

        },
        {
            id: 12,
            name: AppConstants.subCategoriesJwelleryandAccessories[12],
            key: AppConstants.subCategoriesJwelleryandAccessories[12],
            pathName: "handbags"

        },{
            id: 13,
            name: AppConstants.subCategoriesJwelleryandAccessories[13],
            key: AppConstants.subCategoriesJwelleryandAccessories[13],
            pathName: "purses"

        },
    ]

    const [tabValue, setTabValue] = useState<Number>(getTabValue());

    function getTabValue(): Number {
        let pathName = window.location.pathname.split("/");
        let path: any = subCategoryList.find((x: any) => x.pathName === pathName[2]);
        let id: Number = path ? path.id : 0;
        return id;
    }

    return (
        <AppBarMenu>
            <CategoryContainer
                tabValue={6}>
                <ProductDetailsView
                    title={AppConstants.jwelleryandaccessories}
                    description={AppConstants.jwelleryandaccessoriesdesc} />
                <SubCategoryContainer
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    subCategoryList={subCategoryList}>
                    <Outlet />
                </SubCategoryContainer>
            </CategoryContainer>
            <ContactComponent />
        </AppBarMenu>
    );
}
