import { Grid, ImageList } from "@mui/material";
import React, { useState } from "react";
import AppPagiantion from "../../../GlobalComponents/AppPagiantion";
import ImageListComponent from "../../../GlobalComponents/ImageListComponent";
import AppImages from "../../../Globals/AppImages";
import AppModalComponent from "../../../Globals/AppModalComponent";
import { scrollToTop } from "../../../Globals/Helpers";
export default function CottonNightiesScreen(props: any) {
    const pic_details = [
        {
            id: 0,
            imgUrl: AppImages.Mukizh_Cotton_Nightwear1,
        },
        {
            id: 1,
            imgUrl: AppImages.Mukizh_Cotton_Nightwear2,
        },
        {
            id: 2,
            imgUrl: AppImages.Mukizh_Cotton_Nightwear3,
        }, {
            id: 3,
            imgUrl: AppImages.Mukizh_Cotton_Nightwear4,
        }, {
            id: 4,
            imgUrl: AppImages.Mukizh_Cotton_Nightwear5,
        }, {
            id: 5,
            imgUrl: AppImages.Mukizh_Cotton_Nightwear6,
        }, {
            id: 6,
            imgUrl: AppImages.Mukizh_Cotton_Nightwear7,
        },
        {
            id: 7,
            imgUrl: AppImages.Mukizh_Cotton_Nightwear7,
        },
        {
            id: 8,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear1,
        },
        {
            id: 9,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear2,
        },  {
            id: 10,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear3,
        },  {
            id: 11,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear4,
        },  {
            id: 12,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear5,
        },  {
            id: 13,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear6,
        },  {
            id: 14,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear7,
        },  {
            id: 15,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear8,
        },  {
            id: 16,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear9,
        },  {
            id: 17,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear10,
        },  {
            id: 18,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear11,
        },  {
            id: 19,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear12,
        },  {
            id: 20,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear13,
        },  {
            id: 21,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear14,
        },  {
            id: 22,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear15,
        },  {
            id: 23,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear16,
        },  {
            id: 24,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear17,
        },  {
            id: 25,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear18,
        },  {
            id: 26,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear19,
        },  {
            id: 27,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear20,
        },  {
            id: 28,
            imgUrl: AppImages.Mukizh_A_Line_Nightwear21,
        },
       

    ];

    const [open, setOpen] = useState(false);
    const [value, setvalue] = useState<any>(0);

    const handleOpen = (item: any) => {
        setvalue(item?.id)
        setOpen(!open)
    }
    const firstIndex = 0;
    const [pageSize, setPageSize] = useState(12);
    const [page, setPage] = useState(1);
    const [data, setData] = useState(pic_details.slice(firstIndex, pageSize));
    const handleChange = (event: any, value: any) => {
        setPage(value);
        setData(pic_details.slice(firstIndex + pageSize * (value - 1), pageSize * value));
        scrollToTop()

    }; return (

        <div>
            <div className='grid-Outer'>
                <Grid container spacing={2}>
                    {(data || []).map((item: any) =>
                        <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                            <div onClick={() => handleOpen(item)}>
                                <ImageListComponent
                                    imgUrl={item?.imgUrl}
                                />
                            </div>
                        </Grid>
                    )}
                </Grid>

                <AppModalComponent
                    open={open}
                    setOpen={setOpen}
                    pic_details={pic_details}
                    value={value}
                />


            </div>
            <AppPagiantion
                handleChange={handleChange}
                pageSize={pageSize}
                pic_details={pic_details}
                page={page}
            />
        </div>
    )
}