import { Fade, Modal, Box, Grid, ImageListItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';

export default function AppModalComponent(props: any) {
    const {
        setOpen,
        open,
        pic_details,
        value
    } = props;

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const [duplicate, setDuplicate] = useState([]);

    useEffect(() => {
        if (pic_details) {
            let dummy = pic_details
            let tempArray = dummy.splice(0, value);
            dummy.push(...tempArray);
            setDuplicate(dummy)
        }
    }, [value])

    return (
        <>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={open}>
                    <Box
                        className="popup-container"
                        style={{ outline: 'none' }}
                    >
                        <>
                            <Carousel
                                draggable={false}
                                responsive={responsive}
                                keyBoardControl={true}
                                arrows={pic_details?.length == 1 ? false : true}
                                dotListClass="custom-dot-list-style"
                                containerClass="carousel-container"
                                itemClass="carousel-item-padding-40-px"
                                infinite={true}
                            >
                                {(duplicate || [])?.map((item: any) =>
                                    <div className="">

                                        <ImageListItem
                                            className="carousel-background-container"
                                        >
                                            <div className='outer-background'>
                                                <img
                                                    alt="Loading"
                                                    src={item?.imgUrl} />
                                            </div>
                                        </ImageListItem>
                                    </div>
                                )}
                            </Carousel>
                        </>

                    </Box>
                </Fade>


            </Modal>
        </>
    );
}
