import { Typography } from '@mui/material';
import React from 'react';
import "./Products.scss";

interface ProductDetailsViewProps{
    title: String,
    description: String
}

function ProductDetailsView(props: ProductDetailsViewProps) {
    const {
        title,
        description
    } = props;

    return (
        <Typography 
        sx={{marginBottom: 4}}
        className="product-title-container"
        component={'div'}>
            <Typography
            className="heading">{title}</Typography>
            <Typography
            className="tagline">{description}</Typography>
        </Typography>
    );
}

export default ProductDetailsView;