import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AppBarMenu from '../../GlobalComponents/AppBarMenu';
import ContactComponent from '../../GlobalComponents/ContactComponent';
import AppConstants from '../../Globals/Appconstants';
import CategoryContainer from './CategoryContainer';
import ProductDetailsView from './ProductDetailsView';
import SubCategoryContainer from './SubCategoryContainer';

export default function StationaryScreen(props: any) {
    const subCategoryList = [
        {
            id: 0,
            name: AppConstants.subCategoriesStationary[0],
            key: AppConstants.subCategoriesStationary[0],
            pathName: "imported-eraser&sharpners"
        },
        {
            id: 1,
            name: AppConstants.subCategoriesStationary[1],
            key: AppConstants.subCategoriesStationary[1],
            pathName: "imported-pencils"
        },
        {
            id: 2,
            name: AppConstants.subCategoriesStationary[2],
            key: AppConstants.subCategoriesStationary[2],
            pathName: "imported-pens"
        },{
            id: 3,
            name: AppConstants.subCategoriesStationary[3],
            key: AppConstants.subCategoriesStationary[3],
            pathName: "imported-kids-flask"
        },{
            id: 4,
            name: AppConstants.subCategoriesStationary[4],
            key: AppConstants.subCategoriesStationary[4],
            pathName: "imported-pencil-pouch"
        },{
            id: 5,
            name: AppConstants.subCategoriesStationary[5],
            key: AppConstants.subCategoriesStationary[5],
            pathName: "imported-magnet-box"
        },{
            id: 6,
            name: AppConstants.subCategoriesStationary[6],
            key: AppConstants.subCategoriesStationary[6],
            pathName: "imported-slime"
        },{
            id: 7,
            name: AppConstants.subCategoriesStationary[7],
            key: AppConstants.subCategoriesStationary[7],
            pathName: "imported-bts-diary"
        },{
            id: 8,
            name: AppConstants.subCategoriesStationary[8],
            key: AppConstants.subCategoriesStationary[8],
            pathName: "imported-fancy-bottle"
        },{
            id: 9,
            name: AppConstants.subCategoriesStationary[9],
            key: AppConstants.subCategoriesStationary[9],
            pathName: "imported-highlighter"
        },
        {
            id: 10,
            name: AppConstants.subCategoriesStationary[10],
            key: AppConstants.subCategoriesStationary[10],
            pathName: "imported-key-chains"
        },
        {
            id: 11,
            name: AppConstants.subCategoriesStationary[11],
            key: AppConstants.subCategoriesStationary[11],
            pathName: "imported-pencilbox"
        },
    ]

    const [tabValue, setTabValue] = useState<Number>(getTabValue());

    function getTabValue(): Number {
        let pathName = window.location.pathname.split("/");
        let path: any = subCategoryList.find((x: any) => x.pathName === pathName[2]);
        let id: Number = path ? path.id : 0;
        return id;
    }
       
    return (
        <AppBarMenu>
            <CategoryContainer
                tabValue={10}>
                <ProductDetailsView
                    title={AppConstants.stationery}
                    description={AppConstants.stationerydesc} />
                <SubCategoryContainer
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    subCategoryList={subCategoryList}>
                    <Outlet />
                </SubCategoryContainer>
            </CategoryContainer>
            <ContactComponent />
        </AppBarMenu>
    );
}
