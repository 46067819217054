import { Grid, ImageList } from '@mui/material';
import React, { useState } from 'react';
import AppPagiantion from '../../../GlobalComponents/AppPagiantion';
import ImageListComponent from '../../../GlobalComponents/ImageListComponent';
import AppImages from '../../../Globals/AppImages';
import AppModalComponent from '../../../Globals/AppModalComponent';
import { scrollToTop } from '../../../Globals/Helpers';
interface CottonChuditharReadyMadesProps {

}
export default function CottonChuditharReadyMadesScreen(props: CottonChuditharReadyMadesProps) {
    const pic_details = [
        {
            id: 0,
            imgUrl: AppImages.Churidar_1,
        },
        {
            id: 1,
            imgUrl: AppImages.Churidar_2,
        },  {
            id: 2,
            imgUrl: AppImages.Churidar_3,
        },  {
            id: 3,
            imgUrl: AppImages.Churidar_4,
        },  {
            id: 4,
            imgUrl: AppImages.Churidar_5,
        },  {
            id: 5,
            imgUrl: AppImages.Churidar_6,
        },  {
            id: 6,
            imgUrl: AppImages.Churidar_7,
        },  {
            id: 7,
            imgUrl: AppImages.Churidar_8,
        },  {
            id: 8,
            imgUrl: AppImages.Churidar_9,
        },  {
            id: 9,
            imgUrl: AppImages.Churidar_10,
        },  {
            id: 10,
            imgUrl: AppImages.Churidar_11,
        },  {
            id: 11,
            imgUrl: AppImages.Churidar_12,
        },  {
            id: 12,
            imgUrl: AppImages.Churidar_13,
        },  {
            id: 13,
            imgUrl: AppImages.Churidar_14,
        },  {
            id: 14,
            imgUrl: AppImages.Churidar_15,
        },  {
            id: 15,
            imgUrl: AppImages.Churidar_16,
        },  {
            id: 16,
            imgUrl: AppImages.Churidar_17,
        },  {
            id: 17,
            imgUrl: AppImages.Churidar_18,
        },  {
            id: 18,
            imgUrl: AppImages.Churidar_19,
        },  {
            id: 19,
            imgUrl: AppImages.Churidar_20,
        },  {
            id: 20,
            imgUrl: AppImages.Churidar_21,
        },  {
            id: 21,
            imgUrl: AppImages.Churidar_22,
        },  {
            id: 22,
            imgUrl: AppImages.Churidar_23,
        },  {
            id: 23,
            imgUrl: AppImages.Churidar_24,
        },  {
            id: 24,
            imgUrl: AppImages.Churidar_25,
        },  {
            id: 25,
            imgUrl: AppImages.Churidar_26,
        },  {
            id: 26,
            imgUrl: AppImages.Churidar_27,
        },  {
            id: 27,
            imgUrl: AppImages.Churidar_28,
        },  {
            id: 28,
            imgUrl: AppImages.Churidar_29,
        },  {
            id: 29,
            imgUrl: AppImages.Churidar_30,
        },  {
            id: 30,
            imgUrl: AppImages.Churidar_31,
        },  {
            id: 31,
            imgUrl: AppImages.Churidar_32,
        },  {
            id: 32,
            imgUrl: AppImages.Churidar_33,
        },  {
            id: 33,
            imgUrl: AppImages.Churidar_34,
        },  {
            id: 34,
            imgUrl: AppImages.Churidar_35,
        },  {
            id: 35,
            imgUrl: AppImages.Churidar_36,
        },  {
            id: 36,
            imgUrl: AppImages.Churidar_37,
        },  {
            id: 37,
            imgUrl: AppImages.Churidar_38,
        },  {
            id: 38,
            imgUrl: AppImages.Churidar_39,
        },
        {
            id: 39,
            imgUrl: AppImages.Churidar_40,
        },{
            id: 40,
            imgUrl: AppImages.Churidar_41,
        },
   
       

    ];
    const [open, setOpen] = useState(false);
    const [value, setvalue] = useState<any>(0);

    const handleOpen = (item: any) => {
        setvalue(item?.id)
        setOpen(!open)
    }
    const firstIndex = 0;
    const [pageSize, setPageSize] = useState(12);
    const [page, setPage] = useState(1);
    const [data, setData] = useState(pic_details.slice(firstIndex, pageSize));
    const handleChange = (event: any, value: any) => {
        setPage(value);
        setData(pic_details.slice(firstIndex + pageSize * (value - 1), pageSize * value));
        scrollToTop()

    };
    return (
        <div>
            <div className='grid-Outer'>
                <Grid container spacing={2}>
                    {(data || []).map((item: any) =>
                        <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                            <div onClick={() => handleOpen(item)}>
                                <ImageListComponent
                                    imgUrl={item?.imgUrl}
                                />
                            </div>
                        </Grid>
                    )}
                </Grid>

                <AppModalComponent
                    open={open}
                    setOpen={setOpen}
                    pic_details={pic_details}
                    value={value}
                />


            </div>
            <AppPagiantion
                handleChange={handleChange}
                pageSize={pageSize}
                pic_details={pic_details}
                page={page}
            />
        </div>
    );
}
