import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AppBarMenu from '../../GlobalComponents/AppBarMenu';
import ContactComponent from '../../GlobalComponents/ContactComponent';
import AppConstants from '../../Globals/Appconstants';
import CategoryContainer from './CategoryContainer';
import ProductDetailsView from './ProductDetailsView';
import SubCategoryContainer from './SubCategoryContainer';

export default function ShawlsScreen(props: any) {
    const subCategoryList = [
        {
            id: 0,
            name: AppConstants.subCategoriesShawls[0],
            key: AppConstants.subCategoriesShawls[0],
            pathName: "stoles"
        },
        {
            id: 1,
            name: AppConstants.subCategoriesShawls[1],
            key: AppConstants.subCategoriesShawls[1],
            pathName: "shawls"
        }
       
    ]
    const [tabValue, setTabValue] = useState<Number>(getTabValue());

    function getTabValue(): Number {
        let pathName = window.location.pathname.split("/");
        let path: any = subCategoryList.find((x: any) => x.pathName === pathName[2]);
        let id: Number = path ? path.id : 0;
        return id;
    }
    return (
        <AppBarMenu>
            <CategoryContainer
                tabValue={9}>
                <ProductDetailsView
                    title={AppConstants.shawls}
                    description={AppConstants.shawlsdesc} />
                <SubCategoryContainer
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    subCategoryList={subCategoryList}>
                    <Outlet />
                </SubCategoryContainer>
            </CategoryContainer>
            <ContactComponent />
        </AppBarMenu>
    );
}
