import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import AppBarMenu from '../../GlobalComponents/AppBarMenu';
import ContactComponent from '../../GlobalComponents/ContactComponent';
import AppConstants from '../../Globals/Appconstants';
import CategoryContainer from './CategoryContainer';
import ProductDetailsView from './ProductDetailsView';
import SubCategoryContainer from './SubCategoryContainer';

export default function Pants(props: any) {

    const subCategoryList = [
        {
            id: 0,
            name: AppConstants.subCategoriesPalazzoPants[0],
            key: AppConstants.subCategoriesPalazzoPants[0],
            pathName: "cotton-pants"
        },
        {
            id: 1,
            name: AppConstants.subCategoriesPalazzoPants[1],
            key: AppConstants.subCategoriesPalazzoPants[1],
            pathName: "churidar-leggings"
        },
        {
            id: 2,
            name: AppConstants.subCategoriesPalazzoPants[2],
            key: AppConstants.subCategoriesPalazzoPants[2],
            pathName: "pencil-fit-pants"
        },
        {
            id: 3,
            name: AppConstants.subCategoriesPalazzoPants[3],
            key: AppConstants.subCategoriesPalazzoPants[3],
            pathName: "cigarette-pants"
        },
        {
            id: 4,
            name: AppConstants.subCategoriesPalazzoPants[4],
            key: AppConstants.subCategoriesPalazzoPants[4],
            pathName: "palazzo-pants"
        },
        {
            id: 5,
            name: AppConstants.subCategoriesPalazzoPants[5],
            key: AppConstants.subCategoriesPalazzoPants[5],
            pathName: "patiala-pants"
        },
        {
            id: 6,
            name: AppConstants.subCategoriesPalazzoPants[6],
            key: AppConstants.subCategoriesPalazzoPants[6],
            pathName: "jeans"
        },
        {
            id: 7,
            name: AppConstants.subCategoriesPalazzoPants[7],
            key: AppConstants.subCategoriesPalazzoPants[7],
            pathName: "ankle-length-leggings"
        },
        {
            id: 8,
            name: AppConstants.subCategoriesPalazzoPants[8],
            key: AppConstants.subCategoriesPalazzoPants[8],
            pathName: "jeggings"
        },


    ]
    const [tabValue, setTabValue] = useState<Number>(getTabValue());
    function getTabValue(): Number {
        let pathName = window.location.pathname.split("/");
        let path: any = subCategoryList.find((x: any) => x.pathName === pathName[2]);
        let id: Number = path ? path.id : 0;
        return id;
    }
    return (
        <AppBarMenu>
            <CategoryContainer
                tabValue={4}>
                <ProductDetailsView
                    title={AppConstants.pants}
                    description={AppConstants.palazzoPantsDesc} />
                <SubCategoryContainer
                    tabValue={tabValue}
                    setTabValue={setTabValue}
                    subCategoryList={subCategoryList}>
                    <Outlet />
                </SubCategoryContainer>

            </CategoryContainer>
            <ContactComponent />

        </AppBarMenu>
    );
}
