import {
    BrowserRouter,
    Route,
    Routes
} from 'react-router-dom';

import HomeScreen from '../Components/Home/HomeScreen';
import SareesScreen from '../Components/Products/SareesScreen';
import ChuditharReadymadesScreen from '../Components/Products/ChuditharReadymadesScreen';
import SilksScreen from '../Components/Products/Sarees/SilksScreen';
import Poonam from '../Components/Products/Sarees/Poonam';
import ChuditharMaterialsScreen from '../Components/Products/ChuditharMaterialsScreen';
import NightiesScreen from '../Components/Products/NightiesScreen';
import JwelleryandAccessoriesScreen from '../Components/Products/JwelleryandAccessoriesScreen';
import SoftSilksScreen from '../Components/Products/Sarees/SoftSilksScreen';
import CottonScreen from '../Components/Products/Sarees/CottonScreen';
import { TusserScreen } from '../Components/Products/Sarees/TusserScreen';
import SyntheticFibresChuditharReadymades from '../Components/Products/ChuditharReadymades/SyntheticFibresChuditharReadymades';
import CottonChuditharReadyMadesScreen from '../Components/Products/ChuditharReadymades/CottonChuditharReadyMadesScreen';
import CottonChuditharMaterialsScreen from '../Components/Products/ChuditharMaterials/CottonChuditharMaterialsScreen';
import CottonNightiesScreen from '../Components/Products/Nighties/CottonNightiesScreen';
import RayonScreen from '../Components/Products/Nighties/RayonScreen';
import TopsScreen from '../Components/Products/TopsScreen';
import TopsCottonScreen from '../Components/Products/Tops/TopsCottonScreen';
import InSkirtScreen from '../Components/Products/InSkirtScreen';
import InSkirtCottonScreen from '../Components/Products/InSkirt/InSkirtCottonScreen';
import LeggingsScreen from '../Components/Products/KidsWearScreen';
import BanarasiSilkSarees from '../Components/Products/Sarees/BanarasiSilkSarees';
import EmbroiderySareesScreens from '../Components/Products/Sarees/EmbroiderySareesScreen';
import ChuridarReadymadesSilkCottonScreen from '../Components/Products/ChuditharReadymades/ChuridarReadymadesSilkCottonScreen';
import ChuridarReadymadesChickenCurryembroideryScreen from '../Components/Products/ChuditharReadymades/ChuridarReadymadesChickenCurryembroideryScreen';
import EmbroideryChuditharMaterialsScreen from '../Components/Products/ChuditharMaterials/EmbroideryChuditharMaterialsScreen';
import SilkCottonChuditharMaterialScreen from '../Components/Products/ChuditharMaterials/SilkCottonChuditharMaterialScreen';
import PencilFitPalazzoPants from '../Components/Products/pants/PencilFitPalazzoPants';
import CigarettePalazzoPants from '../Components/Products/pants/CigarettePalazzoPants';
import PatialaPalazzoPants from '../Components/Products/pants/PatialaPalazzoPants';
import JeansPalazzoPants from '../Components/Products/pants/JeansPalazzoPants';
import KidsTopsScreen from '../Components/Products/KidsWear/KidsTopsScreen';
import KidsWearPantsScreen from '../Components/Products/KidsWear/KidsWearPantsScreen';
import NecklacesScreen from '../Components/Products/JwelleryandAccessories/NecklacesScreen';
import EarringsScreen from '../Components/Products/JwelleryandAccessories/EarringsScreen';
import BanglesScreen from '../Components/Products/JwelleryandAccessories/BanglesScreen';
import HairClipsScreen from '../Components/Products/JwelleryandAccessories/HairClipsScreen';
import HairBandsScreen from '../Components/Products/JwelleryandAccessories/HairBandsScreen';
import BindisScreen from '../Components/Products/JwelleryandAccessories/BindisScreen';
import NailPolishScreen from '../Components/Products/JwelleryandAccessories/NailPolishScreen';
import AnkletsScreen from '../Components/Products/JwelleryandAccessories/AnkletsScreen';
import NethichutiScreen from '../Components/Products/JwelleryandAccessories/NethichutiScreen';
import SareePinsScreen from '../Components/Products/JwelleryandAccessories/SareePinsScreen';
import ShapeWearScreen from '../Components/Products/InSkirt/ShapeWearScreen';
import SilkTopsScreen from '../Components/Products/Tops/SilkTopsScreen';
import PureCottonTopsScreen from '../Components/Products/Tops/PureCottonTopsScreen';
import RayonTopsScreen from '../Components/Products/Tops/RayonTopsScreen';
import ChickenCurryEmbroideryTopsScreen from '../Components/Products/Tops/ChickenCurryEmbroideryTopsScreen';
import AnkleLengthLeggings from '../Components/Products/pants/AnkleLengthLeggings';
import JeggingsScreen from '../Components/Products/pants/JeggingsScreen';
import { ChuridarLeggingsPants } from '../Components/Products/pants/ChuridarLeggingsPants';
import Pants from '../Components/Products/Pants';
import PalazzoPants from '../Components/Products/pants/PalazzoPants';
import ShawlsScreen from '../Components/Products/ShawlsScreen';
import StolesScreen from '../Components/Products/Shawls/StolesScreen';
import ShawlShawlScreen from '../Components/Products/Shawls/ShawlShawlScreen';
import StationaryScreen from '../Components/Products/StationaryScreen';
import ImportedEraser from '../Components/Products/Stationery/ImportedEraser';
import ImportedPencils from '../Components/Products/Stationery/ImportedPencils';
import ImportedPens from '../Components/Products/Stationery/ImportedPens';
import ImportedKidsFlask from '../Components/Products/Stationery/ImportedKidsFlask';
import ImportedPencilPouches from '../Components/Products/Stationery/ImportedPencilPouches';
import ImportedMagnetBox from '../Components/Products/Stationery/ImportedMagnetBox';
import ImportedSlime from '../Components/Products/Stationery/ImportedSlime';
import ImportedBTSDiary from '../Components/Products/Stationery/ImportedBTSDiary';
import ImportedFancyBottle from '../Components/Products/Stationery/ImportedFancyBottle';
import ImportedHighlighter from '../Components/Products/Stationery/ImportedHighlighter';
import HipBeltScreen from '../Components/Products/JwelleryandAccessories/HipBeltScreen';
import BlouseTasselBeads from '../Components/Products/JwelleryandAccessories/BlouseTasselBeads';
import DesignerSarees from '../Components/Products/Sarees/DesignerSarees';
import RayonChuridarReadyMadesScreen from '../Components/Products/ChuditharReadymades/RayonChuridarReadyMadesScreen';
import KidsWearDressesScreen from '../Components/Products/KidsWear/KidsWearDressesScreen';
import CottonPantsScreen from '../Components/Products/pants/CottonPantsScreen';
import BandhiniSareesScreen from '../Components/Products/Sarees/BandhiniSareesScreen';
import KeychainsScreen from '../Components/Products/Stationery/KeychainsScreen';
import HandBagsScreen from '../Components/Products/JwelleryandAccessories/HandBagsScreen';
import PursesScreen from '../Components/Products/JwelleryandAccessories/PursesScreen';
import ImportedPencilBox from '../Components/Products/Stationery/ImportedPencilBox';
import WesternWearScreen from '../Components/Products/Tops/WesternWearScreen';

export default function Navigates() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<HomeScreen />} />
                    <Route path="/home" element={<HomeScreen />} />
                    <Route path="sarees"
                        element={<SareesScreen />} >
                        <Route
                            path="silk-sarees"
                            element={<SilksScreen />} />
                        <Route
                            path="soft-silk-sarees"
                            element={<SoftSilksScreen />} />
                        <Route
                            path="poonam-sarees"
                            element={<Poonam />} />
                        <Route
                            path="cotton-sarees"
                            element={<CottonScreen />} />
                        <Route
                            path="tussar-sarees"
                            element={<TusserScreen />} />
                        <Route
                            path="banarasi-sarees"
                            element={<BanarasiSilkSarees />} />
                        <Route
                            path="embroidery-sarees"
                            element={<EmbroiderySareesScreens />} />
                        <Route
                            path="designer-sarees"
                            element={<DesignerSarees />} />
                        <Route
                            path="bandhini-sarees"
                            element={< BandhiniSareesScreen />} />
                    </Route>
                    <Route path="/churidar-readymades"
                        element={<ChuditharReadymadesScreen />}>
                        <Route
                            path="cotton-readymades"
                            element={<CottonChuditharReadyMadesScreen />} />
                        <Route
                            path="synthetic-fibre"
                            element={<SyntheticFibresChuditharReadymades />} />
                        <Route
                            path="silk-cotton"
                            element={<ChuridarReadymadesSilkCottonScreen />} />
                        <Route
                            path="chickencurry-embroidery"
                            element={<ChuridarReadymadesChickenCurryembroideryScreen />} />
                        <Route
                            path="rayon"
                            element={<RayonChuridarReadyMadesScreen />} />
                    </Route>
                    <Route path="/churidar-materials" element={<ChuditharMaterialsScreen />} >
                        <Route
                            path="cotton-materials"
                            element={<CottonChuditharMaterialsScreen />} />
                        <Route
                            path="embroidery"
                            element={<EmbroideryChuditharMaterialsScreen />} />
                        <Route
                            path="silk-cotton"
                            element={<SilkCottonChuditharMaterialScreen />} />
                    </Route>
                    <Route path="/tops" element={<TopsScreen />} >
                        <Route
                            path="cotton"
                            element={<TopsCottonScreen />} />
                        <Route
                            path="silk"
                            element={< SilkTopsScreen />} />
                        <Route
                            path="pure-cotton"
                            element={<PureCottonTopsScreen />} />
                        <Route
                            path="rayon"
                            element={< RayonTopsScreen />} />
                        <Route
                            path="chickencurry-embroidery"
                            element={<ChickenCurryEmbroideryTopsScreen />} />
                        <Route
                            path="western-wear"
                            element={<WesternWearScreen />} />

                    </Route>
                    <Route path="/pants" element={<Pants />} >
                        <Route
                            path="churidar-leggings"
                            element={<ChuridarLeggingsPants />} />
                        <Route
                            path="cotton-pants"
                            element={<CottonPantsScreen />} />
                        <Route
                            path="pencil-fit-pants"
                            element={<PencilFitPalazzoPants />} />
                        <Route
                            path="cigarette-pants"
                            element={<CigarettePalazzoPants />} />
                        <Route
                            path="palazzo-pants"
                            element={<PalazzoPants />} />
                        <Route
                            path="patiala-pants"
                            element={<PatialaPalazzoPants />} />
                        <Route
                            path="jeans"
                            element={<JeansPalazzoPants />} />
                        <Route
                            path="ankle-length-leggings"
                            element={<AnkleLengthLeggings />} />
                        <Route
                            path="jeggings"
                            element={< JeggingsScreen />} />
                    </Route>
                    <Route path="/kidswear" element={<LeggingsScreen />} >
                        <Route
                            path="tops"
                            element={<KidsTopsScreen />} />
                        <Route
                            path="pants"
                            element={<KidsWearPantsScreen />} />
                        <Route
                            path="dresses"
                            element={<KidsWearDressesScreen />}
                        />
                    </Route>
                    <Route path="/jwellery-and-accessories" element={<JwelleryandAccessoriesScreen />} >
                        <Route
                            path="necklaces"
                            element={<NecklacesScreen />} />
                        <Route
                            path="earrings"
                            element={<EarringsScreen />} />
                        <Route
                            path="bangles"
                            element={<BanglesScreen />} />
                        <Route
                            path="hairclips"
                            element={<HairClipsScreen />} />
                        <Route
                            path="hairbands"
                            element={<HairBandsScreen />} />
                        <Route
                            path="bindis"
                            element={<BindisScreen />} />
                        <Route
                            path="nailpolish"
                            element={<NailPolishScreen />} />
                        <Route
                            path="anklets"
                            element={<AnkletsScreen />} />
                        <Route
                            path="nethichutti"
                            element={<NethichutiScreen />} />
                        <Route
                            path="saree-pins"
                            element={<SareePinsScreen />} />
                        <Route
                            path="hip-belt"
                            element={<HipBeltScreen />}
                        />
                        <Route
                            path="blouse-tassel-beads"
                            element={<BlouseTasselBeads />}
                        />
                        <Route
                            path="handbags"
                            element={<HandBagsScreen />}
                        />
                        <Route
                            path="purses"
                            element={<PursesScreen />}
                        />

                    </Route>
                    <Route path="/inskirt" element={<InSkirtScreen />} >
                        <Route
                            path="cotton"
                            element={<InSkirtCottonScreen />} />
                        <Route
                            path="shape-wear"
                            element={<ShapeWearScreen />} />
                    </Route>
                    <Route path="/night-wear" element={<NightiesScreen />}
                    >
                        <Route
                            path="cotton"
                            element={<CottonNightiesScreen />} />
                        <Route
                            path="rayon"
                            element={<RayonScreen />} />

                    </Route>
                    <Route path="/shawls" element={<ShawlsScreen />}
                    >
                        <Route
                            path="stoles"
                            element={<StolesScreen />} />
                        <Route
                            path="shawls"
                            element={<ShawlShawlScreen />} />
                    </Route>
                    <Route path="/stationery" element={<StationaryScreen />}
                    >
                        <Route
                            path="imported-eraser&sharpners"
                            element={<ImportedEraser />} />
                        <Route
                            path="imported-pencils"
                            element={<ImportedPencils />} />
                        <Route
                            path="imported-pens"
                            element={<ImportedPens />} />
                        <Route
                            path="imported-kids-flask"
                            element={<ImportedKidsFlask />} />
                        <Route
                            path="imported-pencil-pouch"
                            element={<ImportedPencilPouches />} />
                        <Route
                            path="imported-magnet-box"
                            element={<ImportedMagnetBox />} />
                        <Route
                            path="imported-slime"
                            element={<ImportedSlime />} />
                        <Route
                            path="imported-bts-diary"
                            element={<ImportedBTSDiary />} />
                        <Route
                            path="imported-fancy-bottle"
                            element={<ImportedFancyBottle />} />
                        <Route
                            path="imported-highlighter"
                            element={< ImportedHighlighter />} />
                        <Route
                            path="imported-key-chains"
                            element={< KeychainsScreen />} />
                        <Route
                            path="imported-pencilbox"
                            element={< ImportedPencilBox />} />

                    </Route>

                </Routes>
            </BrowserRouter>
        </div >
    );
}
