import { ImageListItem } from '@mui/material';
import React from 'react';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

export default function ImageListComponent(props: any) {
    const {
        imgUrl,
        imageName,
        pre_Amt,
        cur_Amt,
        discount
    } = props;
    const detalsFunction = () => {
        return (
            <>
                <div className='img-details-container'>
                    <div className="img-name">
                        {imageName ? imageName : "Lorem ipsum Silk Saree"}
                    </div>
                    <div className="img-price">
                        <span className='previous-amount'>
                            <span className='currency '>
                                &#8377;
                            </span>
                            <span className='amount'>
                                {pre_Amt ? pre_Amt : "2500"}
                            </span>
                        </span>
                        <span className='curent-amount'>
                            <span className='currency font-Arial'>
                                &#8377;
                            </span>
                            {cur_Amt ? cur_Amt : "1900"}
                        </span>
                        <span className='discount'>
                            <span className='discount-inner'>
                                Discount - {discount ? discount : "10"}%
                            </span>
                        </span>
                    </div>
                    <div className="Available-div">
                        Available in
                    </div>
                    <div className='website-div'>
                        https://www.shopify.com/
                    </div>
                </div>
            </>
        )
    }

    return (
        <div
        >
            <ImageListItem
                cols={1}
                rows={1}
                className="image-background-container">
                <div className='outer-background'>
                    <img
                        alt="Loading"
                        src={imgUrl} />
                </div>
                {/* {detalsFunction()} */}
            </ImageListItem>
        </div>
    );
}
