
const AppConstants: any = {
    contact_us: "Contact Us",
    contact: "Contact",
    mukizhtagline: "Fashion you hear, we are here",
    socialmedia: "Social Media",
    products: "Products",
    producttagline: "The Most attention grabbing apparels of Mukizh Fashion are Sarees, Churidar materials, Readymade churidar sets, Readymade Kurtis, Shawls (Dupattas), Inskirts, Palazzos, Leggings, Nighties. Accessories we have- Nail polish, Necklaces, Earrings, Clips and Bindis. We are known for our finest fashion apparels and fashion accessories.",
    mukizh: "Mukizh Fashions is the Indian fashion house known for our Best quality fabric clothes & Accessories for Beautiful women. we are fashion apparel store online as well as in-store.",
    sarees: "Sarees",
    kidswear: "Kids wear",
    sareesDesc: "We do have Indian ethnic wear, Premium Quality and fancy Sarees under Cotton silk, Soft silk, Pure silk, Kanchipuram Silk, Copper Silk, Linen and Synthetic (Poonam) categories. Our Sarees collection are ocean in varieties. It is easy to carry and comfortable to wear. Mukizh Fashion makes sure to bring you the best of its ethnic wears at best price. As the store opening we offer 10% discount on each Saree.",
    chuditharReadymades: "Churidar - Readymades",
    chuditharReadymadesDesc: "Mukizh Fashion is the Best place to purchase the Outfits affordable readymade churidar set Includes Churidar Top, Pant and Shawls (Dupattas) as per your Color, Latest fashion, Pattern and design preferences from our huge collection in both online and in-store. Avail 10% Discount as Opening offer.",
    chuditharMaterial: "Churidar - Materials",
    shawlsdesc:"Comfy, soft and daily wear shawls and stoles under different varieties of colours, designs, materials on affordable prices.",
    chuditharMaterialDesc: " Enjoy the integrity of Indian craftsmanship through our organized line of Churidar materials outfits in our dress shop with 10% discount and Custom-Stich as per your way of fashion.",
    nighties: "Nighties",
    nightiesDesc: "Buy Rayon Nighties for women online and In-store from Mugizh Fashion and get 10% discount as Launching offer. Select from wide range of New women's clothing Ladies nightwear of different colors and Patterns.",
    palazzoPants: "Palazzo Pants",
    palazzoPantsDesc: "Everyday is different, so is your outfit. Wear pants as per your mood. We have collection of pants like Churidar Leggings, Jeggings, Jeans, Ankle Length Leggings, Palazzo Pants, Patiala Pants, Pencil fit Pants, Cigarette Pants. Avail it with 10% discounts as Launching offer.",
    jwelleryandaccessories: "Jewellery & Accessories",
    jwelleryandaccessoriesdesc: "Fashion Accessories for the signature moments of your life. Mukizh fashion is known for best jewelleries like Earrings, Necklaces, Hair clips, Nail polishes and Bindis.",
    tops: "Tops & Kurtis",
    topsdesc: "As a Store opening discount of 10%, Slay your ethnic game with the stylish solid collections of Tops & Kurtis. our scope of kurtis presents astonishing choices in a variety of colors and designs.",
    topsandkurtis: "Tops & Kurtis",
    inskirt: "Inskirts",
    pants: "Pants",
    inskirtdesc: "We sell, best in best quality Inskirts under both Peticoats and Shape wears with different fabrics. Buy the Mukizh Fashion's Pure cotton Readymade 7 and 8 parts Saree Inskirts for women with adjustable ropes in it. Avail it with 10% discounts as Launching offer.",
    shawl: "Shawl",
    leggings: "Leggings",
    nightWear: "Night Wear",
    shawls:"Shawls",
    stationery:"Stationery",
    stationerydesc:"To make your passions and interests more creative, Mukizh Fashions exclusively launches imported stationery items with exclusive varieties",
    KidsWeardesc: "For the cutest kids out in town, we have collection of Frocks, Western wear, Tops, Jeans and so on. We do have quality fabrics, Easy to maintain, Variety of colors and designs. Avail it with 10% discounts as Launching offer.",


    subCategoriesSarees: [
     
        "Cotton Sarees",
        "Tussar Sarees",
        "Banarasi Sarees",
        "Embroidery Sarees",
        "Silk Sarees",
        "Soft Silk Sarees",
        "Poonam Sarees",
        "Designer Sarees",
        "Bandhini Sarees"
    ],
  
    subCategoriesChuditharReadyMades: [
        "Cotton",
        "Synthetic fibres",
        "Silk cotton",
        "Chickencurry Embroidery",
        "Rayon"
    ],
    subCategoriesChuditharMaterials: [
        "Cotton",
        "Embroidery",
        "Silk cotton"
    ],
    subCategoriesNighties: [
        "Cotton",
        "Rayon"
    ],
    subCategoriesPalazzoPants: [
        "Cotton Pants",
        "Churidar Leggings",
        "Pencil Fit Pants",
        "Cigarette Pants",
        "Palazzo Pants",
        "Patiala Pants",
        "Jeans",
        "Ankle Length Leggings",
        "Jeggings",
        "Ankle Length Leggings",
        "Jeggings"

    ],
    subCategoriesJwelleryandAccessories: [
        "Necklaces",
        "Earings",
        "Bangles",
        "Hair Clips",
        "Hair Bands",
        "Bindis",
        "Nail Polish",
        "Anklets",
        "Nethi Chutti",
        "Saree Pins",
        "Hip Belt",
        "Blouse Tassel Beads",
        "Handbags",
        "Purses",
    ],
    subCategoriesTops: [
        "Cotton",
        "Silk",
        "Pure Cotton",
        "Rayon",
        "Chickencurry Embroidery",
        "Western Wear"
    ],
    subCategoriesShawls: [
        "Stoles",
        "Shawls",

    ],
    subCategoriesStationary: [
        "Imported Erasers & Sharpners",
        "Imported Pencils",
        "Imported Pens",
        "Imported Kids Flask",
        "Imported Pencil Pouch",
        "Imported Magnet Box",
        "Imported Slime",
        "Imported BTS Diary",
        "Imported Fancy Bottle",
        "Imported Highlighter",
        "Imported Key Chains",
        "Imported Pencil Box"
        


    ],
    subCategoriesInSkirt: [
        "Cotton",
        "Shape Wear"
    ],
    subCategoriesLeggings: [
        "Cotton",
        "Synthetic fibres",

    ],
    subCategoriesKidswear: [
        "Dresses",
        "Tops",
        "Pants",


    ]

};
export default AppConstants;