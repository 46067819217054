import { Grid, Typography } from '@mui/material';
import React from 'react';
import AppConstants from '../Globals/Appconstants';
import AppImages from '../Globals/AppImages';
import './Index.scss';


export default function ContactComponent(props: any) {

    const openSocialMedia = (key: any) => {
        switch (key) {
            case "linked-in":
                window.open("https://www.linkedin.com/company/mukizh-fashions/", "_blank")
                break;
            case "facebook":
                window.open("https://www.facebook.com/profile.php?id=100086049114678", "_blank")
                break;
            case "instagram":
                window.open("https://www.instagram.com/mukizh.fashions/", "_blank")
                break;
            case "twitter":
                window.open("https://twitter.com/MukizhFashions", "_blank")
                break;
            case "you-tube":
                window.open("https://www.youtube.com/@mukizhfashions", "_blank")
                break;
            case "pinterest":
                window.open("https://in.pinterest.com/mukizhfashions/", "_blank")
                break;
            case "Mukizh":
                window.open("https://www.mukizh.com", "_blank")


        }
    }

    const contactMain = () => {
        try {
            return (
                // <div className='contact-main'>
                //     <div className='vector-image'>
                //         hi
                //     </div>
                //     <div className='contact-details'>
                //         hi
                //     </div>

                // </div>
                <div className='contact-main margin-20'>
                    <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'flex', sm: "flex" },
                        }}
                    >
                        <Grid item
                            xl={6} lg={6} md={6} sm={12} xs={12}
                        >
                            <div className='vector-img'> <img src={AppImages.Contact_pic} /></div>

                        </Grid>
                        <Grid item
                            xl={6} lg={6} md={6} sm={12} xs={12}
                        >
                            <div className='details-container-outerdiv mid-center'>
                                <div className='details-container'>
                                    <div className='phonedeatils'>
                                        <div className='icon'>
                                            <img alt="loading" src={AppImages.phoneIcon} />
                                        </div>
                                        <div className='number'>
                                            <div>
                                                <a
                                                    className="contact-number-1"
                                                    href="tel:+91 9940237225">+91 9940237225</a>
                                            </div>,
                                            <div>
                                                <a
                                                    className="contact-number-2"
                                                    href="tel:+91 9655420220">+91 9655420220</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='phonedeatils'>
                                        <div className='icon'>
                                            <img alt="loading" src={AppImages.mailicon} />
                                        </div>
                                        <div className='gmail'>
                                            <a className="mail-id" href={"mailto:" + "mukizh.fashions@gmail.com"}>mukizh.fashions@gmail.com</a>

                                            {/* mukizh.fashions@gmail.com */}
                                        </div>
                                    </div>
                                    <div className='phonedeatils'>
                                        <div className='icon'>
                                            <img alt="loading" src={AppImages.websiteicon} />
                                        </div>
                                        <div className='website cursor-pointer'
                                            onClick={() => openSocialMedia("Mukizh")}
                                        >
                                            www.mukizh.com
                                        </div>
                                    </div>
                                    <div className='phonedeatils'>
                                        <div className='icon'>
                                            <img alt="loading" src={AppImages.location} />
                                        </div>
                                        <div className='address'>
                                            <div className='number-address'>
                                                No.397/A, Station Road, Gnanamoorthy Nagar,

                                            </div>
                                            <div className='address-chennai'>
                                                <div>
                                                    Ambattur, Chennai - 600 053.
                                                </div>
                                                <div>
                                                    (Near Pattaravakkam Railway Station)
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='social-media'>
                                        <div className='social-media-text'>

                                            {AppConstants.socialmedia}
                                        </div>
                                        <div className='Icons'>
                                            <img
                                                className='cursor-pointer'
                                                onClick={() => openSocialMedia("instagram")}
                                                alt="loading" src={AppImages.instaicon} />
                                            <img
                                                className='cursor-pointer'
                                                onClick={() => openSocialMedia("linked-in")}
                                                alt="loading" src={AppImages.linkedin} />
                                            <img
                                                className='cursor-pointer'
                                                onClick={() => openSocialMedia("you-tube")}
                                                alt="loading" src={AppImages.youtubeIcone} />

                                            <img
                                                className='cursor-pointer'
                                                onClick={() => openSocialMedia("pinterest")}
                                                alt="loading" src={AppImages.pinterestIcon} />
                                            <img
                                                className='cursor-pointer'
                                                onClick={() => openSocialMedia("twitter")}
                                                alt="loading" src={AppImages.twitterIcon} />
                                            <img
                                                className='cursor-pointer'
                                                onClick={() => openSocialMedia("facebook")}
                                                alt="loading" src={AppImages.facebookicon} />


                                        </div>

                                    </div>

                                </div>
                            </div>
                        </Grid>

                    </Grid>
                </div>
            )
        } catch (ex) {
            console.log("Error in contactMain::" + ex);
        }
    }
    const titleandTagline = () => {
        return (
            <div className='title-tagline'>
                <div className='contact-title'>
                    {AppConstants.contact}
                </div>
                <div className='contact-tagline'>
                    {/* <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="/"
                        sx={{
                            mr: 2,
                            // display: { xs: 'none', md: 'flex' },
                            // fontFamily: "-apple-system",
                            fontWeight: 700,
                            letterSpacing: '.2rem',
                            textDecoration: 'none',
                            color: 'black'
                        }}
                    > */}
                    {AppConstants.mukizhtagline}

                    {/* </Typography> */}
                </div>
            </div>
        )
    }
    const ContactView = () => {
        try {
            return (
                <div className='contact-view'>
                    {titleandTagline()}
                    {contactMain()}


                </div>
            )
        } catch (ex) {
            console.log("Error in ContactView::" + ex);
        }
    }
    return (
        <div className='contact-component'>
            {ContactView()}
        </div>
    );
}
