import { ImageList, ImageListItem, ImageListItemBar } from '@mui/material';
import React from 'react';
// import Carousel from 'react-material-ui-carousel';
import AppConstants from '../../Globals/Appconstants';
import "react-multi-carousel/lib/styles.css";
import AppImages from '../../Globals/AppImages';
import AppBarMenu from '../../GlobalComponents/AppBarMenu';
import ContactComponent from '../../GlobalComponents/ContactComponent';
import './HomeScreen.scss';
import Carousel from 'react-multi-carousel';


export default function HomeScreen(props: any) {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    let ArrayCarousel = [
        {
            id: 1,
            image: <img alt="Loading" src={AppImages.Carouselone} />
        },
        {
            id: 2,
            image: <img alt="Loading" src={AppImages.Carouseltwo} />
        },
        {
            id: 3,
            image: <img alt="Loading" src={AppImages.Carouselthree} />
        },
        {
            id: 4,
            image: <img alt="Loading" src={AppImages.Carouselfour} />
        },
        {
            id: 5,
            image: <img alt="Loading" src={AppImages.Carouselfive} />
        },
        {
            id: 6,
            image: <img alt="Loading" src={AppImages.Carouselsix} />
        }
    ]


    const ProductView = () => {
        try {
            return (
                <div className='productView'>
                    <div className='titleandtagline'>
                        <div className='title'>
                            {AppConstants.products}
                        </div>
                        <div className='tagline'>
                            {AppConstants.producttagline}
                        </div>

                    </div>
                    <div className='firstrow padding-20'>
                        <ImageList
                            gap={28}
                            sx={{ width: "100%", height: "100%" }}
                            variant="quilted"
                            cols={7}

                        // rowHeight={}
                        >

                            <ImageListItem cols={2} rows={1}

                            >

                                <img alt="Loading" src={AppImages.Sareesproduct} />
                                <h1><ImageListItemBar
                                    title={"Sarees"}
                                /></h1>
                            </ImageListItem>

                            <ImageListItem cols={3} rows={1}>

                                <img alt="Loading" src={AppImages.chudidhaar_readymade_product} />
                                <h1>
                                    <ImageListItemBar
                                        title={"Churidar - Readymades"}


                                    />
                                </h1>
                            </ImageListItem>
                            <ImageListItem cols={2} rows={1}>

                                <img alt="Loading" src={AppImages.chudidhaar_materialsproduct} />
                                <h1>
                                    <ImageListItemBar
                                        title={"Churidar - Materials"}

                                    />
                                </h1>

                            </ImageListItem>

                            <ImageListItem cols={2} rows={2}>

                                <img alt="Loading" src={AppImages.jwelleryandassecories_product} />
                                <h1>

                                <ImageListItemBar
                                    title={"Jewellery & Accessories"}


                                />
                                </h1>
                            </ImageListItem>
                            <ImageListItem cols={2} rows={1}>

                                <img alt="Loading" src={AppImages.nighties_product} />
                                <h1>

                                <ImageListItemBar
                                    title={"Nighties"}
                                />
                                </h1>
                            </ImageListItem>
                            <ImageListItem cols={3} rows={1}>

                                <img alt="Loading" src={AppImages.palazo_pant_product} />
                                <h1>

                                <ImageListItemBar
                                    title={"Palazzo Pants"}
                                />
                                </h1>
                            </ImageListItem>
                            <ImageListItem cols={3} rows={1}>

                                <img alt="Loading" src={AppImages.leggings_product} />
                                <h1>

                                <ImageListItemBar
                                    title={"Leggings"}
                                />
                                </h1>
                            </ImageListItem>
                            <ImageListItem cols={2} rows={1}>

                                <img alt="Loading" src={AppImages.others} />

                            </ImageListItem>
                        </ImageList>
                        {/* <ImageList
                            gap={28}
                            sx={{ width: "100%", height: "100%" }}
                            variant="quilted"
                            cols={7}

                        // rowHeight={121}
                        >
                            <ImageListItem cols={2} rows={2}>

                                <img alt="Loading" src={AppImages.jwelleryandassecories_product} />
                                <ImageListItemBar
                                    title={"Jewellery & Accessories"}


                                />
                            </ImageListItem>
                            <ImageListItem cols={2} rows={1}>

                                <img alt="Loading" src={AppImages.nighties_product} />
                                <ImageListItemBar
                                    title={"Nighties"}
                                />
                            </ImageListItem>
                            <ImageListItem cols={3} rows={1}>

                                <img alt="Loading" src={AppImages.palazo_pant_product} />
                                <ImageListItemBar
                                    title={"Palazzo Pants"}
                                />
                            </ImageListItem>
                            <ImageListItem cols={3} rows={1}>

                                <img alt="Loading" src={AppImages.leggings_product} />
                                <ImageListItemBar
                                    title={"Leggings"}
                                />
                            </ImageListItem>
                            <ImageListItem cols={2} rows={1}>

                                <img alt="Loading" src={AppImages.others} />

                            </ImageListItem>
                        </ImageList> */}

                        {/* <ImageList
                            gap={28}
                            sx={{ width: "100%", height: "100%" }}
                            variant="quilted"
                            cols={4}

                        // rowHeight={121}
                        >

                            <ImageListItem cols={2} rows={1}>

                                <img alt="Loading" src={AppImages.leggings_product} />
                                <ImageListItemBar
                                    title={"Leggings"}
                                />
                            </ImageListItem>
                            <ImageListItem cols={1} rows={1}>

                                <img alt="Loading" src={AppImages.others} />

                            </ImageListItem>
                        </ImageList> */}
                    </div>
                </div>
            )
        } catch (ex) {
            console.log("Error in ProductView::" + ex);
        }
    }

    const taglineView = () => {
        try {
            return (
                <div className='tagline-box'>
                    <b>Mukizh Fashions</b> is the Indian fashion house known for our Best quality fabric clothes & Accessories for Beautiful women. We are fashion apparel store online as well as in-store.

                </div>
            )

        } catch (ex) {
            console.log("Error in taglineView::" + ex);
        }

    }
    const CorouselView = () => {
        try {
            return (
                <div className='carousel-view'>
                    {/* <Carousel
                        // autoPlay={true}
                        // duration={10000}
                        interval={2000}
                        animation="slide"
                        // stopAutoPlayOnHover
                        swipe
                    >

                        {(ArrayCarousel || []).map((item: any) =>
                            <div className="slider-img-container">{item.image}</div>
                        )

                        }

                    </Carousel> */}
                    <Carousel
                        autoPlaySpeed={2500}
                        draggable={false}
                        showDots={true}
                        responsive={responsive}
                        autoPlay={true}
                        keyBoardControl={true}
                        arrows={false}
                        dotListClass="custom-dot-list-style"
                        containerClass="carousel-container"
                        itemClass="carousel-item-padding-40-px"
                        infinite={true}
                    // transitionDuration={2000}
                    // customTransition="all .10"

                    >
                        {(ArrayCarousel || []).map((item: any) =>
                            <div className="slider-img-container">{item.image}</div>
                        )

                        }

                    </Carousel>;

                </div>
            )
        } catch (ex) {
            console.log("Error in CarouselView::" + ex);

        }
    }
    const mainView = () => {
        try {
            return (
                <div className='main-view'>
                    <AppBarMenu 
                    keyId="home"
                    />
                    {CorouselView()}
                    {taglineView()}
                    {ProductView()}
                    <ContactComponent />
                </div>
            )
        }
        catch (ex) {
            console.log("Error in mainView::" + ex);
        }
    }
    return (
        <div className='home-container'>
            {mainView()}
        </div>
    );
}

