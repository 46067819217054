import { Pagination } from '@mui/material';

export default function AppPagiantion(props: any) {
    const {
        handleChange,
        page,
        pageSize,
        pic_details,
    } = props;


    return (
        <div className='flex-end margin-top'>
            {pic_details.length > 12 &&
                <Pagination
                    count={Math.ceil(pic_details.length / pageSize)}
                    page={page}
                    onChange={handleChange}
                    color="primary"
                />
            }
        </div>
    );
}
