import { Grid } from '@mui/material';
import AppModalComponent from '../../../Globals/AppModalComponent';
import React, { useState } from 'react';
import ImageListComponent from '../../../GlobalComponents/ImageListComponent';
import AppImages from '../../../Globals/AppImages';
import AppPagiantion from '../../../GlobalComponents/AppPagiantion';
import { scrollToTop } from '../../../Globals/Helpers';

export default function ChickenCurryEmbroideryTopsScreen(props: any) {
    const pic_details = [
        {
            id: 0,
            imgUrl: AppImages.ChickencurryEmbroideryTops_1,
        },

    ];
    const [open, setOpen] = useState(false);
    const [value, setvalue] = useState<any>(0);

    const handleOpen = (item: any) => {
        setvalue(item?.id)
        setOpen(!open)
    }
    const firstIndex = 0;
    const [pageSize, setPageSize] = useState(12);
    const [page, setPage] = useState(1);
    const [data, setData] = useState(pic_details.slice(firstIndex, pageSize));
    const handleChange = (event: any, value: any) => {
        setPage(value);
        setData(pic_details.slice(firstIndex + pageSize * (value - 1), pageSize * value));
        scrollToTop()

    };
	
    return (
    //     <div>
    //     <div className='grid-Outer'>
    //         <Grid container spacing={2}>
    //             {(data || []).map((item: any) =>
    //                 <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
    //                     <div onClick={() => handleOpen(item)}>
    //                         <ImageListComponent
    //                             imgUrl={item?.imgUrl}
    //                         />
    //                     </div>
    //                 </Grid>
    //             )}
    //         </Grid>

    //         <AppModalComponent
    //             open={open}
    //             setOpen={setOpen}
    //             pic_details={pic_details}
    //             value={value}
    //         />


    //     </div>
    //     <AppPagiantion
    //         handleChange={handleChange}
    //         pageSize={pageSize}
    //         pic_details={pic_details}
    //         page={page}
    //     />
    // </div>
    <></>

    );
}
