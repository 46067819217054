import { Grid, ImageList } from '@mui/material';
import React from 'react';
import ImageListComponent from '../../../GlobalComponents/ImageListComponent';
import AppImages from '../../../Globals/AppImages';

export default function SilkTopsScreen(props: any) {
    let ArrayImg = [1, 2]
    return (

        <Grid container spacing={2}>
            {/* {(ArrayImg || []).map((item: any) =>
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12}>
                    <ImageListComponent
                        imgUrl={AppImages.jwelleryandaccessories} />
                </Grid>
            )} */}
        </Grid>
    )
}

