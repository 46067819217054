import {
    Grid,
    Tabs,
    Tab,
    Box,
    Typography,
    IconButton,
    MenuItem,
    Menu
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

interface SubCategoryContainerProps {
    subCategoryList: any,
    children: any,
    tabValue: any,
    setTabValue: (value: any) => any
}

interface TabPanelProps {
    children?: React.ReactNode;
    id: number;
    tabValue: number;
}


function SubCategoryContainer(props: SubCategoryContainerProps) {
    const {
        subCategoryList,
        children,
        tabValue,
        setTabValue
    } = props;

    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    function setProps(id: number) {

        return {
            id: `sub-category-tab-${id}`,
            'aria-controls': `sub-category-tabpanel-${id}`,
        };
    }

    const handleOpenNavMenu = (event: any) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };
    function TabPanel(props: TabPanelProps) {
        const { children, tabValue, id, ...other } = props;

        return (
            <div
                role="subcategorytabpanel"
                hidden={tabValue !== id}
                id={`sub-category-tabpanel-${id}`}
                aria-labelledby={`sub-category-tab-${id}`}
                {...other} >
                {tabValue === id && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };
    const dummy = () => {

    }
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        {
            (window.location.pathname == "/inskirt/cotton")
                &&
                tabValue == 0
                ?
                dummy()
                :

                <>
                    {setTabValue(newValue)}
                    {navigate(subCategoryList[newValue].pathName)}
                    {scrollToTop()}
                </>

        }
    };

    return (
        <>
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none', lg: 'none', xl: 'none' } }}
                className="icon-box-hand-burger flex-end"
            >
                <IconButton
                    size="large"
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleOpenNavMenu}
                    className='icon-button-handburger'
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorElNav}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorElNav)}
                    onClose={handleCloseNavMenu}
                    sx={{
                        // display: { xs: 'block', md: 'none' },
                    }}
                >
                    <div className='menu-item-custom'>

                        <MenuItem onClick={handleCloseNavMenu}
                            className="menu-product"
                        >
                            {/* <Typography textAlign="center"
                            >Products</Typography> */}
                            <Tabs
                                className="sub-category-tab-custom"
                                orientation="vertical"
                                value={tabValue}
                                // variant="scrollable"
                                // scrollButtons="auto"
                                // allowScrollButtonsMobile
                                // aria-label="scrollable auto tabs example"
                                onChange={handleChange}>
                                {(subCategoryList || []).map((subCategory: any) => (
                                    <Tab
                                        className="tab-custom"
                                        key={subCategory.key}
                                        label={subCategory?.name}
                                        {...setProps(subCategory.id)} />
                                ))}
                            </Tabs>
                        </MenuItem>
                    </div>
                </Menu>
            </Box>
            <Grid container spacing={0}>

                <Grid item
                    sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', xl: 'flex', lg: 'flex' } }}
                    xl={2} lg={2} md={2}
                // xl={2} lg={2} md={2} sm={2} xs={2}
                >
                    <Tabs
                        className="sub-category-tab-custom"
                        orientation="vertical"
                        value={tabValue}
                        // variant="scrollable"
                        // scrollButtons="auto"
                        // allowScrollButtonsMobile
                        // aria-label="scrollable auto tabs example"
                        onChange={handleChange}
                    >
                        {/* (window.location.pathname=="/inskirt/cotton" && tabValue ==0) */}
                        {(subCategoryList || []).map((subCategory: any) => (
                            <Tab
                                className={
                                    (window.location.pathname == "/inskirt/cotton")
                                        &&
                                        tabValue == 0
                                        ?
                                        "tab-custom cursor-not-allowed"
                                        :
                                        "tab-custom"
                                }
                                key={subCategory.key}
                                label={subCategory?.name}
                                {...setProps(subCategory.id)} />
                        ))}
                    </Tabs>
                </Grid>
                <Grid item
                    className="sub-category-tab-custom-img"
                    xl={10} lg={10} md={10} sm={12} xs={12}
                // xl={10} lg={10} md={10} sm={10} xs={10}

                // sx={{
                //     display: { xs: 'none', md: 'flex' },
                // }}
                >
                    {(subCategoryList || []).map((subCategory: any) => {
                        return (
                            <TabPanel
                                key={subCategory.key}
                                tabValue={tabValue}
                                id={subCategory.id}>
                                {children}
                            </TabPanel>
                        )
                    })}
                </Grid>
            </Grid>
        </>
    );
}

export default SubCategoryContainer;