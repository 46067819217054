import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';
import Stack from '@mui/material/Stack';



const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText("#5E05C6"),
  backgroundColor: "#5E05C6",
  borderRadius: "10px",
  '&:hover': {
    backgroundColor: "#5E05C6",
  },
}));

export default function CustomizedButtons(props: any) {
  const {
    Text,
    fontClass
  } = props;


  return (
    <Stack spacing={2} direction="row">
      <ColorButton
        variant="contained"
        onClick={() => {
          // window.scrollTo(0, document.body.scrollHeight);
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
          // document.body.scrollIntoView({behaviour: "smooth"});
        }}
      ><span className={fontClass}>{Text}</span></ColorButton>
    </Stack>
  );
}
