import {
    Grid,
    ImageList, ImageListItem, useMediaQuery, useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import AppPagiantion from '../../../GlobalComponents/AppPagiantion';
import ImageListComponent from '../../../GlobalComponents/ImageListComponent';
import AppImages from '../../../Globals/AppImages';
import AppModalComponent from '../../../Globals/AppModalComponent';
import { scrollToTop } from '../../../Globals/Helpers';

interface SilksScreenProps {
}
export default function SilksScreen(props: SilksScreenProps) {
   
    const pic_details = [
        {
            id: 0,
            imgUrl: AppImages.SilkSarees_1,
        },
        {
            id: 1,
            imgUrl: AppImages.SilkSarees_2,
        },
        {
            id: 2,
            imgUrl: AppImages.SilkSarees_3,
        },
        {
            id: 3,
            imgUrl: AppImages.SilkSarees_4,
        },
        {
            id: 4,
            imgUrl: AppImages.SilkSarees_5,
        },
        {
            id: 5,
            imgUrl: AppImages.SilkSarees_6,
        }, {
            id: 6,
            imgUrl: AppImages.SilkSarees_7,
        },
        {
            id: 7,
            imgUrl: AppImages.SilkSarees_8,
        },
        {
            id: 8,
            imgUrl: AppImages.SilkSarees_9,
        }, {
            id: 9,
            imgUrl: AppImages.SilkSarees_10,
        },
        {
            id: 10,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree1,
        },
        {
            id: 11,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree2,
        },
        {
            id: 12,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree3,
        },  {
            id: 13,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree4,
        },  {
            id: 14,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree5,
        },  {
            id: 15,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree6,
        },  {
            id: 16,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree7,
        },  {
            id: 17,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree8,
        },  {
            id: 18,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree9,
        },  {
            id: 19,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree10,
        },  {
            id: 20,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree11,
        },  {
            id: 21,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree12,
        },  {
            id: 22,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree13,
        },  {
            id: 23,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree14,
        },  {
            id: 24,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree15,
        },  {
            id: 25,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree16,
        },  {
            id: 26,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree18,
        },  {
            id: 27,
            imgUrl: AppImages.Mukizh_Pochampalli_Silk_Saree19,
        },  
        
        

    ];
    const [open, setOpen] = useState(false);
    const [value, setvalue] = useState<any>(0);

    const handleOpen = (item: any) => {
        setvalue(item?.id)
        setOpen(!open)
    }

    const firstIndex = 0;
    const [pageSize, setPageSize] = useState(12);
    const [page, setPage] = useState(1);
    const [data, setData] = useState(pic_details.slice(firstIndex, pageSize));
    const handleChange = (event: any, value: any) => {
        setPage(value);
        setData(pic_details.slice(firstIndex + pageSize * (value - 1), pageSize * value));
        scrollToTop()

    };

    return (
        <div>
            <div className='grid-Outer'>
                <Grid container spacing={2}>
                    {(data || []).map((item: any) =>
                        <Grid item xl={3} lg={3} md={4} sm={6} xs={12}>
                            <div onClick={() => handleOpen(item)}>
                                <ImageListComponent
                                    imgUrl={item?.imgUrl}
                                />
                            </div>
                        </Grid>
                    )}
                </Grid>

                <AppModalComponent
                    open={open}
                    setOpen={setOpen}
                    pic_details={pic_details}
                    value={value}
                />


            </div>
            <AppPagiantion
                handleChange={handleChange}
                pageSize={pageSize}
                pic_details={pic_details}
                page={page}
            />
        </div>
    );
}
